<template>
  <div class="home">
    <my-header></my-header>
    <section class="container pb-1 business-container mar--t mar--l">
      <div class="columns padding--m--20 mobile-col-rev is-gapless is-vcentered">

        <div class="column text-col text-left txt-top-container a-left">
          <div class="display--1 margin--element">
            <div>You want it.</div>
            <div>You get it.</div>
          </div>


          <div class="row">
            <span>
              <img
                class="only--desktop"
                width="30"
                src="../assets/Group-1927.png"
              />
            </span>
             <img
                class="only--mobile checkpoint-mobile"
                src="../assets/Group-1927.png"
              />
            <span class="margin--l--1 display--3 margin--l--8"> Just-in-time Hiring </span>
          </div>
          <div class="row">
            <span>
              <img
                class="only--desktop"
                width="30"
                src="../assets/Group-1927.png"
              />
            </span>
             <img
                class="only--mobile checkpoint-mobile"
                src="../assets/Group-1927.png"
              />
            <span class="margin--l--1 display--3 margin--l--8">
              Trained & Engaged Work4ce
            </span>
          </div>
          <div class="row">
            <span>
              <img
                class="only--desktop"
                width="30"
                src="../assets/Group-1927.png"
              />
            </span>
             <img
                class="only--mobile checkpoint-mobile"
                src="../assets/Group-1927.png"
              />
            <span class="margin--l--1 display--3 margin--l--8">
              No Paperwork or Payroll to Manage
            </span>
          </div>
        </div>

        <div class="column is-three-fifths">
          <img
            class="business-pg-img"
            alt="happy part-time workers"
            width="500"
            src="../assets/Group-1984.png"
          />
        </div>
      </div>
    </section>

    <!-- just in time hiring -->
    <section id="just-in-time">
      <div class="columns is-vcentered">
        <div class="column">
          <img class="creative" alt="" src="../assets/Group-1986.png" />
        </div>
        <div class="column text-center mar--t">
          <div class="pointers padding--m--20">
            <div class="title--1">
              {{ justTime.heading }}
            </div>
            <hr />
            <div
              class="pointer mt-4 mb-4"
              v-for="pointer of justTime.pointers"
              :key="pointer"
            >
              <div class="item-holder">
                <span>
                  <img
                    class="only--desktop"
                    width="30"
                    src="../assets/Group-1927.png"
                  />
                </span>
                 <img
                    class="only--mobile checkpoint-mobile"
                    src="../assets/Group-1927.png"
                  />

                <span class="points display--3">
                  {{ pointer }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Trained & Engaged Work4ce -->
    <section id="trainedWork">
      <div class="columns is-vcentered interested-section">
        <div class="column text-col text-center trained-section">
          <div class="pointers padding--m--20">
            <div class="title--1">
              {{ trainedWork.heading }}
            </div>
            <hr />
            <div
              class="pointer mt-4 mb-2"
              v-for="pointer of trainedWork.pointers"
              :key="pointer"
            >
              <div class="item-holder">
                <span>
                  <img
                    class="only--desktop"
                    width="30"
                    src="../assets/Group-1927.png"
                  />
                </span>
                 <img
                    class="only--mobile checkpoint-mobile"
                    src="../assets/Group-1927.png"
                  />

                <span class="points display--3">
                  {{ pointer }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="column">
          <img class="creative" alt="" src="../assets/Mask-Group-45.png" />
        </div>
      </div>
    </section>

    <!-- No Paperwork or Payroll to Manage -->
    <section id="noPaperWork">
      <div class="columns is-vcentered">
        <div class="column">
          <img class="creative" alt="" src="../assets/Group-1988.png" />
        </div>
        <div class="column text-center mar--t">
          <div class="pointers padding--m--20">
            <div class="title--1">
              {{ noPaperWork.heading }}
            </div>
            <hr />
            <div
              class="pointer mt-4 mb-2"
              v-for="pointer of noPaperWork.pointers"
              :key="pointer"
            >
              <div class="item-holder">
                <span>
                  <img
                    class="only--desktop"
                    width="30"
                    src="../assets/Group-1927.png"
                  />
                </span>
                 <img
                    class="only--mobile checkpoint-mobile"
                    src="../assets/Group-1927.png"
                  />

                <span class="points display--3">
                  {{ pointer }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- interested -->
    <section class="">
      <div class="columns is-vcentered mobile-col-rev">
        <div class="column int-sec-form-container pa-2 text-left int-form-margin-l">
          <div class="mb-2 title--1">Interested ?</div>
          <div class="mb-2 display--3 interest-form">
            Let us know that you are interested. We will get back to you ...
          </div>
          <form class="interest-form" @submit.prevent="submit">
            <div class="control form--mb">
              <label>Name</label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  v-model="bForm.name"
                  placeholder="e.g Alex Smith"
                  required
                />
              </div>
            </div>

            <div class="control form--mb">
              <label>Email</label>
              <div class="control">
                <input
                  class="input"
                  type="email"
                  v-model="bForm.email"
                  placeholder="e.g. youremail@domain.com"
                  required
                />
              </div>
            </div>
            <div class="control form--mb">
              <label>Contact No</label>
              <div class="control">
                <input
                  class="input"
                  type="number"
                  v-model="bForm.mobile"
                  placeholder="e.g. +91-98xxxxxx89"
                  required
                />
              </div>
            </div>
            <div class="control form--mb">
              <label>Business Name</label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  v-model="bForm.businessName"
                  placeholder="Business Name"
                  required
                />
              </div>
            </div>
            <div class="form--mb">
              <label>Message</label>
              <div class="control">
                <textarea
                  v-model="bForm.message"
                  class="textarea"
                  placeholder="Write your message here"
                  required
                ></textarea>
              </div>
            </div>

            <div class="py-4">
              <button type="submit" class="button is-primary">Submit</button>
            </div>
          </form>
        </div>
        <div class="column text-right is-align-self-flex-end">
          <div>
            <img src="../assets/partner.png" alt="partner-image" />
          </div>
        </div>
      </div>
    </section>
    <my-footer></my-footer>
  </div>
</template>
<script>
import header from "../components/Header";
import footer from "../components/Footer";
import { submitForm } from "../service/webservice";

export default {
  components: {
    myFooter: footer,
    myHeader: header,
  },
  metaInfo: {
        title: "Hire On-Demand Workers To Maximise Business Profits | Gigforce",
        meta: [
          {
            name:'description', content: 'Gigforce staffing agency provides local staffing solutions for skilled, on-demand roles, contract staffing. Start hiring today!'
          }
        ]
    },
  data: () => ({
    justTime: {
      heading: "Just in time hiring",
      pointers: [
        "We create a pool of gigers for you to work anytime.",
        "How it works? Fill our roster. Tell us how many people are required on which day. We will ensure those many people are working for you.",
        "We also get you gigs for weekends & evenings.",
      ],
    },
    trainedWork: {
      heading: "Trained & Engaged Work4ce",
      pointers: [
        "We take care of learning & development of the workers",
        "People working as Gigs are more engaged than regular workers.",
        "Our focus is the Gig’s Productivity. Our gigs are managed by productivity grades.",
        "We also get you gigs for weekends & evenings.",
      ],
    },
    noPaperWork: {
      heading: "No Paperwork or Payroll to Manage",
      pointers: [
        "No paperwork for you. Just get the gig, get working.",
        "No Payroll Management by You. Everything managed by us.",
        "All legal & complacencies are managed by us. From his attendance, metering how much the gig worked to payments, everything is managed by Gig4ce.",
      ],
    },
    bForm: {},
  }),
  methods: {
    submit() {
      submitForm(this.bForm, "business")
        .then((suc) => {
          alert(suc.data);
          this.bForm = {};
        })
        .catch((err) => {
          alert(`Oops...some error`);
          console.log(err);
        });
    },
  },
};
</script>
<style  lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.row {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.ml-2 {
  margin-left: 10px;
}

.text-col {
  margin-left: 5rem;
}

#just-in-time {

  min-height: 500px;

  .text-col {
    margin-top: -250px;
  }

  hr {
    margin-top: 2px;
    width: 130px;
    height: 3px;
    background-color: #d71f63;
  }
  .column {
    padding: 0;
    text-align: left;
  }

  .points {
    letter-spacing: 1px;
    padding-bottom: 10px;
    margin-left: 20px;
    max-width: 500px;
  }
  .item-holder {
    display: flex;
    align-items: center;
  }
  .creative {
    position: relative;
    left: 0px;
  }
}

#trainedWork {
  min-height: 500px;
  font-family: "Poppins", sans-serif;
  .text-col {
    margin-top: 5rem;
    margin-left: 5rem;
  }
  hr {
    margin-top: 2px;
    width: 130px;
    height: 3px;
    background-color: #d71f63;
  }
  .column {
    padding: 0;
    text-align: left;
  }
  .points {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 1px;
    padding-bottom: 10px;
    margin-left: 20px;
    max-width: 500px;
  }
  .item-holder {
    display: flex;
    align-items: center;
  }
  .creative {
    position: relative;
    left: 0px;
  }
}

#noPaperWork {
  min-height: 500px;
  font-family: "Poppins", sans-serif;
  .text-col {
    margin-top: -250px;
  }
  hr {
    margin-top: 2px;
    width: 130px;
    height: 3px;
    background-color: #d71f63;
  }
  .column {
    padding: 0;
    text-align: left;
  }
  .points {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 1px;
    padding-bottom: 10px;
    margin-left: 20px;
    max-width: 500px;
  }
  .item-holder {
    display: flex;
    align-items: center;
  }
  .creative {
    position: relative;
    left: 0px;
  }
}

.input,
.textarea,
.select select {
  border: 1px solid #cccccc;
}

label {
  color: #5a5a55;
  font-size: 16px;
}
.input,
.textarea,
.select select {
  border: 1px solid #cccccc;
}

label {
  color: #5a5a55;
  font-size: 16px;
}
.pa-2 {
  padding: 2rem;
}
.int-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.int-sec-main {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.int-sec-form-container {
  width: 40%;
  flex-direction: row;
}



@media only screen and (max-width: 600px) {
  .int-sec-main {
    flex-direction: column;
  }

  .int-sec-form-container {
    width: 100%;
  }

  .interested-section {
    display: flex;
    flex-direction: column-reverse;
  }

  .trained-section {
    margin: 0;
  }

  .text-col {
    margin-left: 0 !important;
  }

  .txt-top-container {
    padding: 10px !important;
  }
}

.business-pg-img {
  width: 600px;
}
</style>
