import axios from 'axios';
import { basePath, prodPath } from './config'

const submitForm = (formData, type) => {
    let url = basePath + `/website/submit/${type}`
    return new Promise((resolve, reject) => {
        axios.post(url, formData)
            .then((resp) => {
                resolve(resp)
            }).catch((err) => {
                reject(err)
            })
    })
}

const getProfileData = (uId) => {
    const url = `${prodPath}/profile-data/${uId}`
    return new Promise((resolve, reject) => {
        axios.get(url, {
            headers: {
                "Access-Control-Allow-Headers": "Content-Type,X-Amz-Date,X-Amz-Security-Token,Authorization,X-Api-Key,X-Requested-With,Accept,Access-Control-Allow-Methods,Access-Control-Allow-Origin,Access-Control-Allow-Headers",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "DELETE,GET,HEAD,OPTIONS,PATCH,POST,PUT"
            }
        }).then(resp => {
            resolve(resp.data)
        })
            .catch(err => {
                reject(err)
            })
    })
}

const earningForm = (formData, type) => {
    let url = basePath + `/website/earning/request?type=${type}`
    return new Promise((resolve, reject) => {
        axios.post(url, formData)
            .then((resp) => {
                resolve(resp)
            }).catch((err) => {
                reject(err)
            })
    })
}

const createKYCTransaction = (uId) => {
    let url = basePath + `/kyc/digilocker/${uId}`
    return new Promise((resolve, reject) => {
        axios.get(url)
            .then((resp) => {
                resolve(resp.data)
            }).catch((err) => {
                reject(err)
            })
    })
}

const verifyKYC = (uId,tId) => {
    let url = basePath + `/kyc/digilocker/verify/${uId}/${tId}`
    return new Promise((resolve, reject) => {
        axios.get(url)
            .then((resp) => {
                resolve(resp.data)
            }).catch((err) => {
                reject(err)
            })
    })
}


export {
    submitForm,
    getProfileData ,
    earningForm ,
    createKYCTransaction ,
    verifyKYC
};
