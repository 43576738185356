<template>
  <div ref="document">
    <progress v-if="isLoading" class="progress is-small is-primary"></progress>
    <vue-html2pdf
      v-if="profile"
      :show-layout="true"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      :filename="profile.name"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="100%"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <div class="section" id="element-to-print">
          <!-- PDF Content Here -->
          <v-card v-if="profile" flat class="main-card">
            <div id="image">
              <v-img
                class="profile-image"
                cover
                alt="Profile Image"
                lazy-src="../../public/img/public_profile_avatar.svg"
                :src="profile.profileUrl"
                style="border-radius: 0.4rem"
                height="15rem"
              ></v-img>
              <v-img
                v-if="isVerified"
                cover
                class="verified-image"
                alt="Verifed Image"
                src="../assets/icon/check.svg"
                style="border-radius: 0.4rem"
              ></v-img>
            </div>
            <div id="info">
              <div style="display: flex">
                <h1>{{ profile.name }}</h1>

                <!-- Verified  -->
                <!-- <h2 class="verified">
                <v-icon>mdi-check-circle</v-icon>
                Verified
              </h2> -->

                <!-- Not Verified -->
                <!-- <h2 class="not-verified">
                <v-icon>mdi-check-circle</v-icon>
                Not Verified
              </h2> -->
              </div>
              <div class="contact">
                <div>
                  <v-icon left>mdi-phone</v-icon>
                  <span>
                    {{ profile.contactNo || "Mobile not Available" }}
                  </span>
                </div>
                <div>
                  <v-icon class="border-icon" left>mdi-email</v-icon
                  ><span> {{ profile.email || "-" }}</span>
                </div>
              </div>
              <div class="chips">
                <!-- <span> <v-icon>mdi-star</v-icon>4.5</span> -->
                <span v-if="profile.currentAddress.city">
                  <v-icon>mdi-map-marker</v-icon>
                  {{ profile.currentAddress.city || "Not available" }}
                </span>
                <span v-for="(skill, i) in profile.skills" :key="skill.id + i">
                  <v-icon>mdi-briefcase</v-icon>
                  {{ skill.id }}
                </span>
                <span>
                  <v-icon>mdi-school</v-icon>
                  {{ profile.highestQualification || "Not available" }}
                </span>
              </div>
              <h3 v-if="profile.aboutMe">{{ profile.aboutMe }}</h3>
              <h3 v-else>About info not available</h3>
              <h5>
                Joined Gigforce since :
                <span v-if="profile.joiningDate">
                  {{ dateFormat(profile.joiningDate, "ll") }}
                </span>
                <span v-else>Date not available </span>
              </h5>
            </div>
            <v-spacer></v-spacer>
            <div id="cta" data-html2canvas-ignore="true">
              <div id="download" @click="generateReport">
                <v-icon
                  style="
                    color: white;
                    margin-right: 0.2rem;
                    font-size: 1.2rem;
                    text-align: center;
                  "
                  >mdi-download</v-icon
                >
                Download
              </div>
            </div>
          </v-card>

          <div class="section-2">
            <div style="flex: 1">
              <v-card flat class="experience">
                <div class="title">
                  <v-icon>mdi-briefcase</v-icon>
                  <h1>Experience</h1>
                </div>
                <div v-if="profile.experience.length" class="content">
                  <div
                    v-for="(exp, i) in profile.experience"
                    :key="exp + i"
                    class="list-item"
                  >
                    <div class="icon">
                      <v-img
                        cover
                        class="verified-image"
                        alt="Verifed Image"
                        src="../assets/icon/experience-icon.svg"
                        max-height="40"
                        min-height="40"
                        max-width="40"
                        min-width="40"
                        style="border-radius: 0.4rem"
                      ></v-img>
                    </div>
                    <div class="info">
                      <span class="profile-title">{{ exp.title }}</span>
                      <span
                        >{{ exp.company }}
                        <i v-if="exp.totalExperence">
                          , {{ exp.totalExperence }} Yrs</i
                        ></span
                      >
                      <span
                        >{{ dateFormat(exp.startDate, "ll") }} -
                        {{ dateFormat(exp.endDate, "ll") }}</span
                      >
                    </div>
                  </div>
                </div>
                <div v-else class="no-content">
                  <h3>No Experience Added Yet.</h3>
                </div>
              </v-card>

              <v-card flat class="education">
                <div class="title">
                  <v-icon>mdi-school</v-icon>
                  <h1>Education</h1>
                </div>
                <div v-if="profile.education.length" class="content">
                  <div
                    v-for="(edu, i) in profile.education"
                    :key="edu + i"
                    class="list-item"
                  >
                    <div class="icon">
                      <v-img
                        cover
                        class="verified-image"
                        alt="Verifed Image"
                        src="../assets/icon/education.svg"
                        max-height="40"
                        min-height="40"
                        max-width="40"
                        min-width="40"
                        style="border-radius: 0.4rem"
                      ></v-img>
                    </div>
                    <div class="info">
                      <span class="profile-title">{{ edu.institution }}</span>
                      <span
                        >{{ edu.degree }}
                        <i v-if="edu.course"> , {{ edu.course }}</i></span
                      >
                      <span
                        >{{ dateFormat(edu.startYear, "ll") }} -
                        {{ dateFormat(edu.endYear, "ll") }}</span
                      >
                    </div>
                  </div>
                </div>
                <div v-else class="no-content">
                  <h3>No Education Details Added Yet.</h3>
                </div>
              </v-card>

              <v-card flat class="documents" v-if="anyDocUploaded">
                <div class="title">
                  <v-icon>mdi-folder</v-icon>
                  <h1>Documents</h1>
                </div>
                <div class="content">
                  <div
                    class="list-item"
                    v-if="
                      profile.documents.aadhar &&
                      profile.documents.aadhar.isUploaded
                    "
                  >
                    <div class="icon">
                      <v-img
                        cover
                        class="verified-image"
                        alt="Verifed Image"
                        src="../assets/icon/documents.svg"
                        max-height="35"
                        min-height="35"
                        max-width="30"
                        min-width="30"
                        style="border-radius: 0.4rem"
                      ></v-img>
                    </div>
                    <div class="info">
                      <span class="profile-title">Aadhaar Card</span>
                      <span> </span>
                      <span> </span>
                    </div>
                  </div>

                  <div
                    class="list-item"
                    v-if="
                      profile.documents.pan && profile.documents.pan.isUploaded
                    "
                  >
                    <div class="icon">
                      <v-img
                        cover
                        class="verified-image"
                        alt="Verifed Image"
                        src="../assets/icon/documents.svg"
                        max-height="35"
                        min-height="35"
                        max-width="30"
                        min-width="30"
                        style="border-radius: 0.4rem"
                      ></v-img>
                    </div>
                    <div class="info">
                      <span class="profile-title">Pan Card</span>
                      <span> </span>
                      <span> </span>
                    </div>
                  </div>

                  <div
                    class="list-item"
                    v-if="
                      profile.documents.dl &&
                      profile.documents.dl.isUploaded
                    "
                  >
                    <div class="icon">
                      <v-img
                        cover
                        class="verified-image"
                        alt="Verifed Image"
                        src="../assets/icon/documents.svg"
                        max-height="35"
                        min-height="35"
                        max-width="30"
                        min-width="30"
                        style="border-radius: 0.4rem"
                      ></v-img>
                    </div>
                    <div class="info">
                      <span class="profile-title">Driving License</span>
                      <span> </span>
                      <span> </span>
                    </div>
                  </div>

                  <div
                    class="list-item"
                    v-if="
                      profile.documents.bank &&
                      profile.documents.bank.isUploaded
                    "
                  >
                    <div class="icon">
                      <v-img
                        cover
                        class="verified-image"
                        alt="Verifed Image"
                        src="../assets/icon/documents.svg"
                        max-height="35"
                        min-height="35"
                        max-width="30"
                        min-width="30"
                        style="border-radius: 0.4rem"
                      ></v-img>
                    </div>
                    <div class="info">
                      <span class="profile-title">Bank Passbook</span>
                      <span> </span>
                      <span> </span>
                    </div>
                  </div>
                </div>
              </v-card>
              <div v-else class="no-content">
                <h3>Documents Not Available.</h3>
              </div>
            </div>

            <div style="flex: 1" class="address-contact">
              <v-card flat class="address">
                <div class="title">
                  <v-icon>mdi-map-marker</v-icon>
                  <h1>Current Address</h1>
                </div>
                <div
                  v-if="
                    profile.currentAddress.city && profile.currentAddress.state
                  "
                  class="address-info"
                >
                  <span>{{
                    profile.currentAddress.firstLine || "Line 1 not available"
                  }}</span>
                  <span>{{
                    profile.currentAddress.secondLine || "Line 2 not available"
                  }}</span>
                  <span
                    >{{ profile.currentAddress.city || "City not available" }} ,
                    {{
                      profile.currentAddress.state || "State not available"
                    }}</span
                  >
                  <span>{{
                    profile.currentAddress.pincode || "Pincode not available"
                  }}</span>
                </div>
                <div v-else class="no-content">
                  <h3>Current Address Not Available.</h3>
                </div>
              </v-card>
              <v-card flat class="contact">
                <div class="title">
                  <v-icon>mdi-phone-message</v-icon>
                  <h1>Contact Info</h1>
                </div>
                <div class="contact-info">
                  <span>
                    <v-icon>mdi-phone-message</v-icon>
                    {{ profile.contactNo || "Mobile not available" }}</span
                  >
                  <span>
                    <v-icon>mdi-whatsapp</v-icon>
                    {{ profile.whatsApp || "Whatsapp not available" }}</span
                  >
                  <span>
                    <v-icon>mdi-email</v-icon>
                    {{ profile.email || "Email not available" }}</span
                  >
                </div>
              </v-card>
            </div>
          </div>
        </div>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
// import html2pdf from "html2pdf.js";
import { getProfileData } from "../service/webservice";
import moment from "moment";

export default {
  components: {
    VueHtml2pdf,
  },
  data() {
    return {
      userId: null,
      profile: null,
      isLoading: false,
      anyDocUploaded: false,
      isVerified: false,
    };
  },
  methods: {
    //Format Date.
    dateFormat(value, format = "DD/MM/YYYY") {
      if (value) {
        return moment.unix(value._seconds).format(format);
      }
      return "";
    },

    //Get User Profile.
    getProfile() {
      this.isLoading = true;
      getProfileData(this.userId)
        .then((response) => {
          this.profile = response;
          console.log(this.profile);
          if (!this.profile.profileUrl) {
            this.profile.profileUrl =
              "https://gigforce.in/img/public_profile_avatar.svg";
          }

          this.anyDocUploaded =
            (this.profile.documents.aadhar &&
              this.profile.documents.aadhar.isUploaded) ||
            (this.profile.documents.pan &&
              this.profile.documents.pan.isUploaded) ||
            (this.profile.documents.bank &&
              this.profile.documents.bank.isUploaded) ||
            (this.profile.documents.dl && this.profile.documents.dl.isUploaded);

          this.isVerified =
            (this.profile.documents.aadhar &&
              this.profile.documents.aadhar.isUploaded) ||
            (this.profile.documents.pan &&
              this.profile.documents.pan.isUploaded) ||
            (this.profile.documents.dl && this.profile.documents.dl.isUploaded);

          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },

    //Generate HTML 2 PDF Report.
    generateReport() {
      //[Vue HTML2PDF].
      this.$refs.html2Pdf.generatePdf();

      // html2pdf(this.$refs.document, {
      //   // margin: 1,
      //   filename: this.profile.name,
      //   image: { type: "jpeg", quality: 0.98 },
      //   html2canvas: { dpi: 192, letterRendering: true },
      //   jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      // });

      //[OLD STYLE HTML2PDF].
      // var element = document.getElementById("element-to-print");
      // var opt = {
      //   margin: 1,
      //   filename: this.profile.name,
      //   image: { type: "jpeg", quality: 0.98 },
      //   html2canvas: { scale: 2 },
      //   jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      // };

      // // New Promise-based usage:
      // html2pdf().set(opt).from(element).save();
    },
  },
  mounted() {
    this.userId = this.$route.params.id;
    this.getProfile();
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400&display=swap");
html,
body,
* {
  box-sizing: border-box;
  text-align: left;
  padding: 0;
  margin: 0;
  line-height: 1;
}
.section {
  background-color: #f2f5f8;
  height: 100%;
  padding: 0.1rem;
  .main-card {
    color: rgb(29, 28, 28);
    padding: 1rem;
    min-height: 13rem;
    margin: 2rem;
    display: flex;
    #image {
      position: relative;
      .profile-image {
        height: 100%;
        width: 180px;
      }
      .verified-image {
        top: -0.9rem;
        left: -0.9rem;
        position: absolute;
      }
    }
    #info {
      margin: 0 1rem;
      h1 {
        text-transform: uppercase;
        color: #494747;
        line-height: 1.5;
        font-weight: 700;
        font-size: 1.7rem;
        font-family: "Lato" sans-serif;
        margin-bottom: 1rem;
      }
      .verified {
        height: 2rem;
        border-radius: 12rem;
        margin-left: 2rem;
        margin-top: 0.2rem;
        padding: 0.5rem 1rem;
        color: white;
        background-color: #4caf50;
        .v-icon {
          color: white;
          margin-top: -0.1rem;
          font-size: 1.3rem;
        }
      }
      .not-verified {
        height: 2rem;
        border-radius: 12rem;
        margin-left: 2rem;
        margin-top: 0.2rem;
        padding: 0.5rem 1rem;
        color: white;
        background-color: #90a4ae;
        .v-icon {
          color: white;
          margin-top: -0.1rem;
          font-size: 1.3rem;
        }
      }
      .contact {
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px solid rgb(235, 231, 231);
        padding-bottom: 0.5rem;
        & > div {
          display: inline-block;
        }
        span {
          display: inline-block;

          margin-right: 1rem;
          font-size: 0.9rem;
          font-weight: bold;
        }
        .v-icon {
          margin-right: 0.5rem;
        }
        .border-icon {
          border-left: 1px solid rgb(235, 231, 231);
          padding-left: 1rem;
        }
      }

      .chips {
        display: flex;
        flex-wrap: wrap;
        margin: 1rem 0;
        span {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0.2rem 0.4rem;
          font-size: 0.8rem;
          color: rgba(15, 6, 6, 0.85);
          font-weight: bold;
          padding: 0.5rem 1rem;
          border-radius: 1.2rem;
          border: solid 1px #e42c64;
          background-color: rgba(255, 233, 239, 0.4);
          .v-icon {
            color: #d72467;
            font-size: 1.3rem;
            margin-right: 0.5rem;
          }
        }
      }
      h3 {
        font-size: 1rem;
        margin-top: 2rem;
        color: 333333;
      }
      h5 {
        font-size: 0.8rem;
        color: rgb(165, 163, 163);
        margin-top: 1.2rem;
      }
    }
    #cta {
      #download {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin: 1rem;
        font-size: 1rem;
        padding: 0.7rem 2rem;
        background-color: #cb1d6a;
        border-radius: 0.2rem;
        color: white;
        font-weight: bold;
        background-image: linear-gradient(72deg, #d72467 -5%, #990077 109%);
      }
    }
  }

  .section-2 {
    display: flex;
    margin: -1.5rem 1rem;
    padding: 1rem;
    justify-content: space-between;
    .v-icon {
      color: #151212;
    }
    .title {
      display: flex;
      align-items: center;
      padding: 1.2rem;
      h1 {
        color: #333333;
        font-size: 1.2rem;
        font-weight: bold;
        margin-left: 0.5rem;
      }
    }
    .experience,
    .education,
    .documents {
      margin-bottom: 3rem;
      .content {
        margin: -1.5rem 3.5rem;
        display: flex;
        flex-direction: column;
        .list-item {
          display: flex;
          border-bottom: 1px solid rgb(235, 231, 231);
          padding-bottom: 1rem;
          margin-bottom: 1rem;
          .icon {
            background-color: #ffeef8;
            padding: 2rem;
          }
          .info {
            display: flex;
            flex-direction: column;
            margin: 0 1rem;
            .profile-title {
              font-size: 1rem;
              font-weight: bold;
              margin-bottom: 0.5rem;
            }
            span {
              font-family: "Lato" sans-serif;
              font-size: 0.755rem;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.25;
              letter-spacing: normal;
              text-align: left;
              color: #666666;
            }
          }
        }
      }
      .no-content {
        margin: -1.5rem 3.5rem;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        color: #a5a3a3;
      }
    }
    .address-contact {
      margin-left: 1rem;
      display: flex;
      flex-direction: column;
      min-width: 42%;

      .address {
        width: 100%;
        min-height: 10rem;
        margin-bottom: 2rem;
        .address-info {
          margin: -2rem 3.5rem;
          display: flex;
          flex-direction: column;
          span {
            font-size: 1rem;
            margin-top: 5px;
          }
        }
        .no-content {
          display: flex;
          justify-content: center;
          align-content: center;
          align-items: center;
          color: #a5a3a3;
        }
      }
      .contact {
        width: 100%;
        min-height: 14rem;
        margin-bottom: 2rem;
        margin-top: -0.5rem;
        padding-bottom: 2.5rem;
        .contact-info {
          margin: -1.5rem 3.5rem;
          display: flex;
          flex-direction: column;
          span {
            font-size: 1rem;
            margin-top: 10px;
            padding-bottom: 1rem;
            border-bottom: 2px solid #dbd8d8;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .section {
    padding: 1rem;
    margin: 0;
    .main-card {
      padding: 2rem;
      margin: 0;
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;
      #image {
        .verified-image {
          top: -1.5rem;
          left: -1.5rem;
          height: 5rem;
          width: 5rem;
        }
        .profile-image {
          height: 100%;
          width: 500px;
        }
      }
      #info {
        margin: 1rem 0;
        h1 {
          font-size: 2.4rem;
        }
        .contact {
          .border-icon {
            border: none;
            padding: 1rem 0;
          }
          span {
            font-size: 1rem;
          }
        }
      }
      #cta {
        #download {
          padding: 1rem;
          margin: 1rem 0 -1rem 0;
        }
      }
    }
    .section-2 {
      display: flex;
      flex-direction: column;
      margin: 0;
      width: 100%;
      padding: 0;
      .experience,
      .education,
      .documents {
        min-width: 0;
        width: 100%;
        margin-bottom: 3rem;
        .content {
          .list-item {
            .info {
              .profile-title {
                font-size: 1.2rem;
              }
              span {
                font-size: 0.9rem;
              }
            }
          }
        }
      }
      .address-contact {
        margin-left: 0;
      }
    }
  }
}
</style>