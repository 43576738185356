<template>
  <div class="home">
    <my-header></my-header>
    <section class="container partner-main-container container-center pb-1 mar--t mar--l">
      <div class="columns padding--m--20 is-gapless is-vcentered mobile-col-rev">
        <div class="column text-col mar--l--5 text-left">
          <div class="display--1">Let's Partner</div>

          <div class="mt-4 display--2">
            Collaborate with us to re-engineer the way people will learn and
            earn.
          </div>

          <div class="mt-4 mb-5 display--3">
            Help us grow skilled gigforce and develop the ecosystem for both
            gigers and businesses.
          </div>

          <div class="py-4">
            <router-link to="/sign-up">
            <button class="button is-primary is-large">Join Today</button>
            </router-link>
          </div>
        </div>

        <div class="column">
          <img
            alt="happy part-time workers"
            width="500"
            src="../assets/Group-1914.png"
          />
        </div>
      </div>
    </section>
    <section id="why-partners">
      <div class="columns is-vcentered">
        <div class="column">
          <img class="creative" alt="" src="../assets/Group-1944.png" />
        </div>
        <div class="column text-center mar--t">
          <div class="pointers padding--m--20 mar-b-50">
            <div class="title--1">
              {{ whyPartner.heading }}
            </div>
            <hr />
            <div
              class="pointer mt-4 mb-2"
              v-for="pointer of whyPartner.pointers"
              :key="pointer"
            >
              <div class="item-holder">
                <span>
                  <img
                    class="only--desktop"
                    width="30"
                    src="../assets/Group-1927.png"
                  />
                </span>
               
                  <img
                    class="only--mobile checkpoint-mobile"
                    src="../assets/Group-1927.png"
                  />
        

                <span class="points display--3">
                  {{ pointer }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="partner-contact">
      <div class="columns is-vcentered mar--t--60">
        <div class="column text-col text-left">
          <div class="title--1">Let's talk</div>
          <hr />
          <div class="display--3">
            As partners we collaborate, innovate and build the future of work
            together. So, hit us up with your questions, thoughts, and ideas.
          </div>
          <div>
            <img src="../assets/partner1.png" width="700" alt="partner-image" />
          </div>
        </div>
        <div class="column text-left mar--t">
          <form @submit.prevent="submit" class="lets-talk-form-w">
            <div class="control form-mb">
              <label>Name</label>
              <div class="control">
                <input
                  class="input"
                  v-model="form.name"
                  type="text"
                  placeholder="e.g Alex Smith"
                  required
                />
              </div>
            </div>

            <div class="control form-mb">
              <label>Email</label>
              <div class="control">
                <input
                  class="input"
                  v-model="form.email"
                  type="email"
                  placeholder="e.g. youremail@domain.com"
                  required
                />
              </div>
            </div>
            <div class="control form-mb">
              <label>Contact No</label>
              <div class="control">
                <input
                  class="input"
                  v-model="form.contactNo"
                  type="number"
                  placeholder="e.g. +91-98xxxxxx89"
                  required
                />
              </div>
            </div>
            <div class="control form-mb">
              <label>Business Name</label>
              <div class="control">
                <input
                  class="input"
                  v-model="form.businessName"
                  type="text"
                  placeholder="Business Name"
                  required
                />
              </div>
            </div>
            
            <div class="form-mb">
              <label>Message</label>
              <div class="control">
                <textarea
                  class="textarea"
                  v-model="form.message"
                  placeholder="Write your message here"
                  required
                ></textarea>
              </div>
            </div>

            <div class="py-4">
              <button type="submit" class="button is-primary">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </section>
    <my-footer></my-footer>
  </div>
</template>
<script>
import header from "../components/Header";
import footer from "../components/Footer";
import { submitForm } from "../service/webservice";

export default {
  name: "partners",
  metaInfo: {
        title: "Partner With Us | Gigforce",
        meta: [
          {
            name:'description', content: `Let's partner. Learn more about our partnership model and how we're providing solutions for the gig economy. Collaborate now!`
          }
        ]
    },
  data: () => ({
    whyPartner: {
      heading: "We would like to partner with …",
      pointers: [
        "Learning & Development Businesses & Platforms",
        "Training & Content Providers",
        "Colleges & Universities",
        "Job Boards, Marketplaces & Employment Exchange",
        "Staffing Businesses",
        "Service Businesses & Agencies",
      ],
    },
    form: {},
  }),
  components: {
    myFooter: footer,
    myHeader: header,
  },
  methods: {
    submit() {
      submitForm(this.form, "partner")
        .then((suc) => {
          alert(suc.data);
          this.form = {};
        })
        .catch((err) => {
          alert(`Oops...some error`);
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
.home {
  margin-top: 14%;
}
#why-partners {
  min-height: 500px;
  font-family: "Poppins", sans-serif;

  hr {
    margin-top: 2px;
    width: 130px;
    height: 3px;
    background-color: #d71f63;
  }
  .column {
    padding: 0;
    text-align: left;
  }
  .points {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 1px;
    padding-bottom: 10px;
    margin-left: 20px;
  }
  .item-holder {
    display: flex;
    align-items: center;
  }
  .creative {
    position: relative;
    left: 0px;
  }
}

.input,
.textarea,
.select select {
  border: 1px solid #cccccc;
}

#partner-contact {
  label {
    color: #5a5a55;
    font-size: 16px;
  }

  form {
    padding: 15px;
  }

  width: 80%;
  margin: 0 auto;
}
hr {
    margin-top: 2px;
    width: 130px;
    height: 3px;
    background-color: #d71f63;
  }

@media only screen and (max-width: 768px) {
  .text-col {
    margin-left: 0 !important;
  }
}
</style>