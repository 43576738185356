<template>
  <div class="main-container">
    <div class="mk-container">
      <myMkHeader></myMkHeader>

      <h1 class="display--1 mar--t--60 has-text-weight-semibold">Media</h1>
      <div class="mediaPageContainer">
        <div class="mediaCompanyLogo">
          <img :src="CardData.companyLogo" alt="" />
        </div>
        <div class="mediaPageContent">
          <h1 class="display--1 bold">{{ CardData.heading }}</h1>
          <h3 class="display--3">{{ CardData.date }}</h3>
          <div class="mediaImg">
            <img :src="CardData.img" alt="" />
          </div>
          <div
            v-for="para in CardData.description"
            :key="para"
            :class="para.content"
            :style="{ fontWeight: mediacontent(para.font), fontSize: `20px` }"
          >
            {{ para.content }}
          </div>
          <div class="mediaPageLink">
            <h2 class="display--2 color-red bold">Read More at :</h2>
            <a
              class="display--3 color-blue"
              :href="CardData.link"
              target="_blank"
              >{{ CardData.link }}</a
            >
          </div>
        </div>
      </div>
      <myFooter></myFooter>
    </div>
  </div>
</template>
<script>
import footer from "../components/Footer";
import Mkheader from "../components/MarketingHeader";

export default {
  name: "MediaPage",
  components: {
    myFooter: footer,
    myMkHeader: Mkheader,
  },
  data() {
    return {
      CardData: {},
    };
  },
  created() {
    this.CardData = this.$route.params.data;
    console.log(this.CardData);
  },
  methods: {
    mediacontent(font) {
      if (font == "normal") {
        return 400;
      } else {
        return 700;
      }
    },
  },
};
</script>
<style>
.mediaPageContainer {
  width: 90%;
  margin: 60px auto 40px auto;
  display: flex;
  flex-direction: column;
}
.mediaCompanyLogo {
  text-align: left;
}
.mediaCompanyLogo img {
  width: 200px;
  /* height: 200px; */
  object-fit: contain;
}
.mediaImg img {
  width: 80%;
  height: 80%;
  object-fit: contain;
  display: flex;
  align-items: center !important;
  margin: 0 auto !important;
  border-radius: 14px;
}
.mediaPageContent {
  text-align: left;
}
.mediaPageContent > * {
  padding-top: 30px;
}
.color-red {
  color: red;
}
.color-blue {
  color: blue;
}
.ediaPageLink{
    display: flex;
    flex-direction: column;
    align-items: left;
}
.mediaPageLink > * {
  padding: 10px;
}
.bold {
  font-weight: bold;
}
</style>