<template>
  <div>
     
        <myMkHeader></myMkHeader>

        <myFooter></myFooter>
  </div>
</template>
<script>
import footer from "../components/Footer";
import Mkheader from "../components/MarketingHeader";

export default {
  components: {
    myFooter: footer,
    myMkHeader: Mkheader,
  },
  data: () => ({
    
  }),
  methods: {
    
  },
};
</script>
<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

</style>