<template>
  <div
    style="
      margin: 0 auto;
      margin-top: 10vh;
      width: 1400px;
      padding: 5vh;
      background-color: #f5f5f5;
    "
  >
    <div class="columns Rectangle">
      <div class="column is-one-fifth">
        <img class="Logo_GigForce_V2" src="../assets/gigforce.png" />
      </div>
      <div class="column">
        <input type="text" placeholder="search" class="bg" />
      </div>
    </div>
    <div class="Rectangle-Copy-3 columns" style="height: 232px">
      <div class="column is-3">
        <img
          style="
            width: 160px;
            height: 183px;
            margin: 2px 17px 0 0;
            border-radius: 4px;
          "
          :src="profileData.profileUrl"
        />
      </div>
      <div class="column">
        <h3 style="float: left">{{ profileData.name }}</h3>
        <img
          v-if="profileData.verified"
          title="Verified"
          style="height: 20px; margin-left: 10px"
          src="../assets/icon/done.png"
        /><br />
        <p style="font-size: 12px; color: #888888">
          Joined-Gigforce-since-
          {{ dateFormat(profileData.createdOn, "MM-YYYY") }}
        </p>
        <div class="columns">
          <!-- <div class="column is-2">
            <div class="Rectangle-rounded">
              <div>
                <img src="../assets/icon/star.png" />{{
                  profileData.rating ? profileData.rating.total : 0
                }}
              </div>
            </div>
          </div> -->
          <div class="column is-2 Rectangle-rounded">
            <div>
              <img src="../assets/icon/Icon.png" />{{
                profileData.currentAddress.city
              }}
            </div>
          </div>
          <!-- <div class="column is-3">
            <div class="Rectangle-rounded">
              <div><img src="../assets/icon/24.png" /> 2y+ Work Ex</div>
            </div>
          </div> -->
          <div class="column is-3">
            <div class="Rectangle-rounded">
              <div>
                <img src="../assets/icon/graduation.png" />{{
                  profileData.highestQualification
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="Rectangle-Copy-3"
      v-if="profileData.videoUrl"
      style="height: 72vh"
    >
      <div class="heading">
        <img src="../assets/icon/play.png" /> sample video
      </div>
      <!-- <video style="width: 100%; margin: 5px 20%"  controls autoplay>
        <source :src="VerificationDetails.videoUrl" />
      </video> -->
      <video style="height: 60vh" :src="profileData.videoUrl" controls></video>
    </div>
    <div class="Rectangle-Copy-3" style="min-height: 200px">
      <div class="heading"><img src="../assets/icon/sq.png" /> Experience</div>
      <div
        class="columns"
        style="padding: 4vh 8vh"
        v-for="(item, index) in profileData.experience"
        :key="index"
      >
        <div class="column is-1">
          <div
            style="
              margin-top: 2vh;
              background: lightgray;
              padding-top: 14%;
              height: 58px;
              text-align: center;
              width: 45px;
            "
          >
            {{ item.company[0].toUpperCase() }}
          </div>
        </div>
        <div class="column is-10 pl-3">
          <strong>{{ item.title }}</strong>
          <div style="font-size: 12px; color: #888888; margin-top: -2%">
            {{ item.company }}
          </div>
          <div style="font-size: 14px; color: #888888; margin-top: -2%">
            {{ dateFormat(item.startDate, "MMM YY") }}-{{
              dateFormat(item.endDate, "MMM YY")
            }}
          </div>
        </div>
      </div>
    </div>
    <div class="Rectangle-Copy-3" style="min-height: 200px">
      <div class="heading">
        <img src="../assets/icon/graduation_b.png" /> Education
      </div>
      <div
        class="columns"
        style="padding: 4vh 8vh"
        v-for="(item, index) in profileData.education"
        :key="'e' + index"
      >
        <div class="column is-1 pt-2">
          <img
            src="../assets/icon/graduation.png"
            style="background: lightgray; padding: 14px; height: 45px"
          />
        </div>
        <div class="column is-10 pl-3">
          <strong>{{ item.institution }}</strong>
          <div style="font-size: 12px; color: #888888; margin-top: -2%">
            {{ item.degree }}
          </div>
          <div style="font-size: 14px; color: #888888; margin-top: -2%">
            {{ dateFormat(item.startYear, "MMM YY") }} -
            {{ dateFormat(item.endYear, "MMM YY") }}
          </div>
        </div>
      </div>
    </div>
    <div class="Rectangle-Copy-3" style="min-height: 200px">
      <div class="heading">
        <img src="../assets/icon/folder.png" /> Documents
      </div>
      <div class="columns" style="padding: 4vh 8vh">
        <div class="column is-1 pt-2">
          <img
            v-if="
              profileData.documents.aadhar &&
              profileData.documents.aadhar.isVerified
            "
            src="../assets/icon/doc_a.png"
            style="background: lightgray; padding: 5px; height: 45px"
          />
          <img
            v-else
            src="../assets/icon/24.png"
            style="background: lightgray; padding: 12px; height: 45px"
          />
        </div>
        <div class="column is-10 pl-3">
          <strong>Aadhaar Card</strong>
          <div style="font-size: 12px; color: #888888; margin-top: -2%">
            {{
              profileData.documents.aadhar
                ? "Uploaded on " +
                  dateFormat(profileData.documents.aadhar.uploadOn)
                : "Not Uploaded"
            }}
          </div>
        </div>
      </div>
      <div class="columns" style="padding: 4vh 8vh">
        <div class="column is-1 pt-2">
          <img
            v-if="
              profileData.documents.pan &&
              profileData.documents.pan.isVerified == 1
            "
            src="../assets/icon/doc_a.png"
            style="background: lightgray; padding: 5px; height: 45px"
          />
          <img
            v-else
            src="../assets/icon/24.png"
            style="background: lightgray; padding: 12px; height: 45px"
          />
        </div>
        <div class="column is-10 pl-3">
          <strong>Pan Card</strong>
          <div style="font-size: 12px; color: #888888; margin-top: -2%">
            {{
              profileData.documents.pan
                ? "Uploaded on " +
                  dateFormat(profileData.documents.pan.uploadOn)
                : "Not Uploaded"
            }}
          </div>
        </div>
      </div>
    </div>
    <div class="Rectangle-Copy-3" style="min-height: 200px">
      <div class="heading"><img src="../assets/icon/sq.png" /> Others</div>
      <div class="columns" style="padding: 4vh 8vh">
        <div class="column is-1 pt-2">
          <div
            style="
              background: lightgray;
              padding-top: 7%;
              height: 45px;
              text-align: center;
              width: 45px;
            "
          >
            {{ profileData.langauge ? profileData.langauge.length : 0 }}
          </div>
        </div>
        <div class="column pl-3">
          <strong>Langauge Known</strong>
          <div class="columns pl-3">
            <div
              v-for="(item, index) in profileData.langauge"
              :key="'l' + index"
              class="column is-1"
              style="font-size: 12px; color: #888888; margin: 5px"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="columns" style="padding: 4vh 8vh">
        <div class="column is-1 pt-2">
          <div
            style="
              background: lightgray;
              padding-top: 7%;
              height: 45px;
              text-align: center;
              width: 45px;
            "
          >
            {{ profileData.skills ? profileData.skills.length : 0 }}
          </div>
        </div>
        <div class="column is-10 pl-3">
          <strong>Skills</strong>
          <div class="columns pl-3">
            <div
              v-for="(item, index) in profileData.skills"
              :key="'l' + index"
              class="column is-3"
              style="font-size: 12px; color: #888888; margin: 5px"
            >
              {{ item.id }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="Rectangle-Copy-3" style="min-height: 200px">
      <div class="heading">
        <img src="../assets/icon/Group -call.png" /> Contact Info
      </div>
      <div class="columns" style="padding: 2vh 8vh">
        <img
          src="../assets/icon/Group -call.png"
          style="height: 15px"
          class="mt-3 mr-4"
        />
        {{ profileData.contactNo }}
      </div>
      <div class="columns" style="padding: 0 8vh">
        <img
          src="../assets/icon/message.png"
          style="height: 15px"
          class="mt-3 mr-4"
        />
        {{ profileData.whatsApp }}
      </div>
      <div class="columns" style="padding: 0 8vh">
        <img
          src="../assets/icon/email.png"
          style="height: 15px"
          class="mt-3 mr-4"
        />
        {{ profileData.email }}
      </div>
    </div>

    <!-- <div class="Rectangle-Copy-3" style="min-height: 200px">
      <div class="heading">
        <img src="../assets/icon/Group -call.png" /> Social Media
      </div>
      <div class="columns" style="padding: 2vh 8vh">
        <img
          src="../assets/icon/linkedin.png"
          style="height: 15px"
          class="mt-3 mr-4"
        />
        linked.com
      </div>
      <div class="columns" style="padding: 0 8vh">
        <img
          src="../assets/icon/facebook.png"
          style="height: 15px"
          class="mt-3 mr-4"
        />
        facebook.com
      </div>
    </div> -->

    <div class="Rectangle-Copy-3" style="min-height: 200px">
      <div class="heading">
        <img src="../assets/icon/location_b.png" /> Current Address
      </div>
      <div style="padding: 1vh 8vh; text-align: left">
        {{ profileData.currentAddress.firstLine }}<br />
        {{ profileData.currentAddress.secondLine }}<br />
        {{
          profileData.currentAddress.city +
          "," +
          profileData.currentAddress.state
        }}<br />
        {{ profileData.currentAddress.pincode }}<br />
      </div>
    </div>

    <div class="Rectangle-Copy-3" style="min-height: 200px">
      <div class="heading"><img src="../assets/icon/Group.png" /> About Me</div>
      <div class="columns" style="padding: 2vh 8vh">
        {{ profileData.aboutMe }}
      </div>
    </div>

    <div class="Rectangle-Copy-3" style="min-height: 200px">
      <div class="heading">
        <img src="../assets/icon/sq.png" /> More About User
      </div>
      <div class="columns" style="padding: 4vh 8vh">
        <div class="column is-1 pt-2">
          <img
            src="../assets/icon/car.png"
            style="
              background: lightgray;
              padding: 10px;
              height: 45px;
              width: 45px;
            "
          />
        </div>
        <div class="column is-10 pl-3">
          <strong>Owner Vehicle</strong>
          <!-- <div class="columns">
            <div class="column is-1 Rectangle-rounded" style="border: none">
              car
            </div>
            <div class="column is-1 Rectangle-rounded" style="border: none">
              Bike
            </div>
          </div> -->
        </div>
      </div>

      <div class="columns" style="padding: 4vh 8vh">
        <div class="column is-1 pt-2">
          <img
            src="../assets/icon/Icon.png"
            style="
              background: lightgray;
              padding: 14px;
              height: 40;
              width: 45px;
            "
          />
        </div>
        <div class="column is-10 pl-3">
          <strong>Ready To Migrate</strong>
          <div class="columns">
            <!-- <div class="column is-1 Rectangle-rounded" style="border: none">
              Yes
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

import { getProfileData } from "../service/webservice";

export default {
  data() {
    return {
      userId: null,
      profileData: {
        name: '',
        highestQualification: '',
        videoUrl: '',
        profileUrl:'',
        experience: [],
        documents: {},
        education: [],
        langauge: [],
        skills: [],
        currentAddress: {},
        aboutMe: "",
        contactNo: "",
        email: "",
      }
    };
  },
  methods: {
    dateFormat(value, format = "DD/MM/YYYY") {
      if (value) {
        return moment.unix(value._seconds).format(format);
      }
      return "";
    },
    fetchProfile() {
      getProfileData(this.userId)
        .then((data) => {
          this.profileData = data
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.userId = this.$route.params.userid;
    this.fetchProfile();
    // this.profileData = {
    //   name: "Aakash",
    //   highestQualification: "Bachelor's",
    //   videoUrl:
    //     "https://storage.googleapis.com/gig4ce-app.appspot.com/verification_selfie_videos/vid_20201216_130100.mp4?GoogleAccessId=firebase-adminsdk-37ypg%40gig4ce-app.iam.gserviceaccount.com&Expires=2057702400&Signature=TejMEDxfd9Re98qCw7Ab77zI9vul3RVgsAq7KoE%2BL2ErWo7JRq8u0tO7gqqWqq%2FetYMb7BJsu2btHvFZkxmHHfe2b5ioyEX5QTSFEoXLgvMHlrO0DNOLLy9tEA8Jl%2FwRDS5j72k2xYjbBS7hazL0rCunJiQpwKDZuuY6BNELLD0F0siF2uTHWWrJlY3CAZjRD6Xzw50VCna1pW8DYqdFYsVrHjy%2BYw%2Bkus%2B6fQQRXA6WWrN4OwpMuBefz5ZVnnL%2BH%2FVUezszBbgUw3swjzpiitg0Nf3tL6s4S6%2FaISK0viPHun72VBHzhPd0vJoVDtLaDaE2lNvDWYsMYsNuGFZbXw%3D%3D",
    //   profileUrl:
    //     "https://storage.googleapis.com/gig4ce-app.appspot.com/profile_pics/IMG_20201221_114630_.jpg?GoogleAccessId=firebase-adminsdk-37ypg%40gig4ce-app.iam.gserviceaccount.com&Expires=2057702400&Signature=RNfciwW5%2B2bUSnk9Xgqyn%2FEr1%2B2qaJgFtYQbtf%2BDe8HFerlN9TGKWS%2FlX%2BjY5uauldGMIt1YzOxfmjgFuGgUegpeYBBlj%2FGvJzMYurZgFhzp7DURKkzr8ArXKPDpCfppPdy%2BwOSY9nv6t3rbHmQlHJmrhWH2eP0cHMNBabc9%2BcGO20hbbgRWRqgNJ0a0M2QPrnOw3nJ6ni12tw6Qt1Ty8Eep1XE4bYWzJQvtgaQM7HSvf%2B8FnkpW25JHFrt1n4i1bgEHQ33itqeBdK2L9i2ZG5pf0rBnatYNQ0KYZZgSb9eUAHxYdehr%2B59JbXHUX%2FnzIgWsu0aVQvpM%2FdDlolwxGA%3D%3D",
    //   experience: [
    //     {
    //       company: "Teamlease",
    //       currentExperience: false,
    //       startDate: {
    //         _seconds: 1588444200,
    //         _nanoseconds: 0,
    //       },
    //       title: "APM",
    //       tableName: "experiences",
    //       employmentType: "Full time",
    //       endDate: {
    //         _seconds: 1588789800,
    //         _nanoseconds: 0,
    //       },
    //       location: "bangalore",
    //     },
    //   ],
    //   documents: {
    //     aadhar: {
    //       isVerified: true,
    //     },
    //     pan: {
    //       isVerified: false,
    //     },
    //   },
    //   education: [
    //     {
    //       institution: "MVN",
    //       startYear: {
    //         _seconds: 1599417000,
    //         _nanoseconds: 0,
    //       },
    //       brief: "",
    //       degree: "Bachelor",
    //       endYear: {
    //         _seconds: 1599503400,
    //         _nanoseconds: 0,
    //       },
    //       tableName: "educations",
    //       course: "12tg",
    //     },
    //     {
    //       startYear: {
    //         _seconds: 1599417000,
    //         _nanoseconds: 0,
    //       },
    //       activities: "",
    //       field: "",
    //       degree: "Bachelor",
    //       endYear: {
    //         _seconds: 1599503400,
    //         _nanoseconds: 0,
    //       },
    //       brief: "",
    //       course: "12th",
    //       institution: "MVN",
    //       tableName: "educations",
    //       educationDocument: null,
    //     },
    //   ],
    //   langauge: [
    //     {
    //       speakingSkill: "29",
    //       tableName: "languages",
    //       writingSkill: "56",
    //       name: "hindi",
    //       motherLanguage: true,
    //     },
    //   ],
    //   skills: [
    //     {
    //       tableName: "skills",
    //       id: "Waiter",
    //     },
    //   ],
    //   currentAddress: {
    //     firstLine: "Ballabgarh",
    //     pincode: "121004",
    //     state: "Chandigarh",
    //     city: "Chandigarh",
    //     preferredDistanceActive: true,
    //     empty: false,
    //     area: "Faridabad",
    //     preferred_distance: 14,
    //     secondLine: "abc",
    //   },
    //   aboutMe: "This is bio. I am driver",
    //   contactNo: "+919983088869",
    //   email: "Aakash.ag@gmail.com",
    // };
  },
};
</script>

<style scoped>
.Rectangle {
  height: 64px;
  padding: 11px 285px 12px 96.7px;
  background-color: #ffffff;
}

.Logo_GigForce_V2 {
  width: 144px;
  height: 27px;
  margin: 7.3px 270.3px 6.7px 0;
}

.bg {
  width: 100%;
  height: 41px;
  /* margin: 0 89px 0 270.3px; */
  padding: 11px 27px 15px 16px;
  border-radius: 5px;
  border: solid 1px #e42c64;
  background-color: rgba(163, 6, 116, 0);
}

.Rectangle-Copy-3 {
  margin: 24px 70px 32px;
  padding: 23px 339px 24px 24px;
  border-radius: 4px;
  border: solid 1px #efefef;
  background-color: #ffffff;
}
.Rectangle-rounded {
  height: 20px;
  margin: 13px 25px 7px 14px;
  padding: 0px 22px 32px 14px;
  border-radius: 15px;
  border: solid 1px #e42c64;
  background-color: rgba(255, 233, 239, 0.4);
  font-size: 12px;
  overflow: hidden;
}

.Rectangle-rounded div {
  display: flex;
}

.Rectangle-rounded div img {
  width: 16px;
  height: 16px;
  margin: 7px 7px 0px -3px;
}
.heading {
  text-align: left;
  font-size: 17px;
  font-weight: bold;
  border-bottom: 1px solid lightgray;
  width: 139%;
}
.heading img {
  height: 18px;
  margin-right: 10px;
  margin-top: 10px;
}
</style>