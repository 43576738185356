<template>
  <div class="staffing-agency-in-india-container">
    <div class="mk-container" id="marketingform">
      <myMkHeader></myMkHeader>
      <div class="marketing-section-1 pd-80">
        <section class="columns">
          <div class="column is-half staff-easy-content">
            <h1 class="mk-mt-50 display--1 staff-easy-heading">
              Staffing is Easy
            </h1>
            <h3 class="mk-mt-20 display--2">
              Let Gigforce manage your workforce better.
            </h3>
          </div>
          <div class="column is-half is-mobile mk-form-section">
            <div class="card">
              <div
                class="card-content form-container"
                id="marketing-contactform"
              >
                <div class="title--1 form-title has-text-centered">
                  Let's Connect
                </div>
                <form @submit.prevent="submit" class="text-left">
                  <div class="control pt-2">
                    <label>Name</label>
                    <div class="control">
                      <input
                        class="input"
                        type="text"
                        v-model="bForm.name"
                        placeholder="e.g Alex Smith"
                        required
                      />
                    </div>
                  </div>

                  <div class="control pt-2">
                    <label>Email</label>
                    <div class="control">
                      <input
                        class="input"
                        type="email"
                        v-model="bForm.email"
                        placeholder="e.g. youremail@domain.com"
                        required
                      />
                    </div>
                  </div>
                  <div class="control pt-2">
                    <label>Contact No</label>
                    <div class="control">
                      <input
                        class="input"
                        type="number"
                        v-model="bForm.mobile"
                        placeholder="e.g. +91-98xxxxxx89"
                        required
                      />
                    </div>
                  </div>
                  <div class="control pt-2">
                    <label>Business Name</label>
                    <div class="control">
                      <input
                        class="input"
                        type="text"
                        v-model="bForm.businessName"
                        placeholder="Business Name"
                        required
                      />
                    </div>
                  </div>
                  <div class="control">
                    <label>Message</label>
                    <div class="control">
                      <textarea
                        class="textarea"
                        placeholder="Write your message here"
                        v-model="bForm.message"
                        required
                      ></textarea>
                    </div>
                  </div>

                  <div class="py-4 mk-submit-btn">
                    <button type="submit" class="button is-primary mt-20">
                      Contact Us
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div>
        <div class="column is-one-third">
          <img
            class="mk-creative"
            alt=""
            src="../assets/mk-gigforce-creative.png"
          />
        </div>
      </div>

      <div id="why-gig" class="m-t--100">
        <div class="wg-container">
          <div class="display--1 why-gig-heading">Why Gigforce ?</div>
          <div class="columns mk-why-gig-card-container">
            <div class="why-gig-card custom-card">
              <div class="card-content">
                <div class="mk-title--2">Better Giger<sup>®️</sup>  Behavior</div>
                <div class="mk-title--3 mt-76">
                  Reward punctuality, consistency and grooming using our
                  proprietary technology
                </div>
              </div>
            </div>
            <div class="why-gig-card custom-card">
              <div class="card-content">
                <div class="mk-title--2">Transparent Pricing</div>
                <div class="mk-title--3 mb-76 custom-spacing">
                  Rest assured on what is being paid to Giger<sup>®️</sup> 
                </div>
              </div>
            </div>
            <div class="why-gig-card custom-card">
              <div class="card-content">
                <div class="mk-title--2">Pre-Trained Gigers</div>
                <div class="mk-title--3 mt-76">
                  We provide you Gigers<sup>®️</sup>  already trained in your industry
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section class="contact-sales-section">
        <div class="columns contact-sales-section-container">
          <div class="column is-three-fifths contact-sales-content">
            <h1 class="display--1 bold white">Contact Sales</h1>
            <h3 class="white sales-title-1">
              Need Staff ASAP? Gigforce connects you with immediate hourly or
              daily workers. Tell us how Gigforce can help your business.
            </h3>
          </div>
          <div class="column contact-sales-btn">
            <div class="py-4 contact-sales-submit-btn">
              <button
                type="submit"
                class="button mt-20"
                v-scroll-to="'#marketingform'"
              >
                Contact Us
              </button>
            </div>
            <!-- <p class="white">or write us at hello@gig4ce.com</p> -->
          </div>
        </div>
      </section>

      <div>
        <div id="slider-area" class="is-vcentered is-hcentered roles">
          <div class="display--1 slider-heading">Our Roles</div>
          <div class="carousel">
            <carousel
              :perPage="carouselCount"
              :autoplay="true"
              :loop="true"
              :centerMode="true"
              :mouse-drag="true"
              :paginationActiveColor="'#D71F63'"
            >
              <slide
                v-for="(item, index) in carouselImages"
                :key="'role' + index"
              >
                <div class="mx-2 carousel-card detail-card slider-card">
                  <div class="carousel-card-content">
                    <img :src="item.img" class="crImage" :alt="item.text" />
                    <div class="display--3 p-10-0">{{ item.text }}</div>
                  </div>
                </div>
              </slide>
            </carousel>
          </div>
        </div>
      </div>

      <myFooter></myFooter>
    </div>
  </div>
</template>
<script>
import { Carousel, Slide } from "vue-carousel";
import footer from "../components/Footer";
import { submitForm } from "../service/webservice";
import Mkheader from "../components/MarketingHeader";

export default {
  components: {
    Carousel,
    Slide,
    myFooter: footer,
    myMkHeader: Mkheader,
  },
  data: () => ({
    carouselCount: 3,
    bForm: {},
    carouselImages: [
      {
        text: "Retail Store Manager",
        img: "img/roles/Retail-Store-Manager.jpg",
      },
      {
        text: "Barista",
        img: "img/roles/2. Barista.jpg",
      },
      {
        text: "Store Executives",
        img: "img/roles/executive.jpg",
      },
      {
        text: "Work From Home Gigs",
        img: "img/roles/4. Work From Home  Gigs.jpg",
      },
      {
        text: "Car Driver",
        img: "img/roles/5. Car Driver.jpg",
      },
      {
        text: "Truck Driver",
        img: "img/roles/driver.jpg",
      },
      {
        text: "Auto Driver",
        img: "img/roles/7. Auto Driver.jpg",
      },
      {
        text: "Bikers",
        img: "img/roles/10. Bikers.jpg",
      },
    ],
  }),
  created() {
    window.addEventListener("resize", this.getCrousalCount);
  },
  mounted() {
    this.getCrousalCount();
  },
  destroyed() {
    window.removeEventListener("resize", this.getCrousalCount);
  },
  methods: {
    getCrousalCount() {
      let wd = window.innerWidth;
      console.log("carousel coount");

      if (wd < 750) {
        this.carouselCount = 1;
      } else if (wd >= 750 && wd < 1200) {
        this.carouselCount = 2;
      } else {
        this.carouselCount = 3;
      }
    },
    submit() {
      submitForm(this.bForm, "marketing")
        .then((suc) => {
          alert(suc.data);
          this.bForm = {};
        })
        .catch((err) => {
          alert(`Oops...some error`);
          console.log(err);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

// .VueCarousel-slide{
//   flex-basis: unset;
// }
.staffing-agency-in-india-container {
  max-width: 1400px;
  min-width: 280px;
  margin: 0 auto;
}
.columns {
  width: 96%;
  margin: 0 auto;
}
.wg-container {
  width: 90%;
  margin: 0 auto;
}
.marketing-section-1 {
  background-color: #f8f8f8;
  background-image: url(../assets/Ellipse1.png), url(../assets/Ellipse2.png),
    url(../assets/Ellipse3.png), url(../assets/Ellipse4.png);
  background-repeat: no-repeat, no-repeat;
  background-position: 93% 20%, 0% 17%, 25% 93%, 50% 83%;
  // background-position: 90% calc(150 * 0.05vw), 0% 17%, 25% 93%, 50% 83%;
}
.mk-mt-50 {
  margin-top: 50px;
}
.mk-mt-20 {
  margin-top: 20px;
}
.card {
  width: 550px;
  // height: 600px;
  height: 690px;
  border-radius: 4px;
}
.single-card {
  width: 100%;
  // height: 20rem;
}
.form-container {
  // margin-top: 14px;
  // margin-top: 8px;
}
.single-card .card-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.input,
.textarea,
.select select {
  border: 1px solid #cccccc;
  border-radius: 12px;
  margin-top: 4px;
}
form {
  // margin-top: 6px;
}
form label {
  color: #333333;
}
.mt-30 {
  margin-top: 30px;
}
.staff-easy-content {
  display: flex;
  flex-direction: column;
  margin-top: 100px;
}
.mk-submit-btn {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.contact-sales-submit-btn button {
  background: #ffffff;
  color: #333333;
  width: 200px;
  border-radius: 36px;
  font-size: 18px;
  font-weight: bold;
}
.mk-submit-btn button {
  border-radius: 24px;
  width: 200px;
}
.mk-creative {
  position: relative;
  left: -20px;
  top: -150px;
  // left: -5px;
}
.why-gig-card {
  display: flex;
  width: 350px;
  height: 350px;
  border-radius: 18px;
  background-color: #ffffff;
  // box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
  color: #757763;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  margin: 20px;
  box-shadow: 12px 12px 40px 0 rgba(55, 84, 170, 0.1),
    12px 12px 20px 0 rgba(0, 0, 0, 0.1), -12px -12px 80px 0 white,
    inset 4px 4px 20px 0 white;
}
.card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.mk-why-gig-card-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
.why-gig-heading,
.staff-easy-heading {
  font-weight: bold;
  color: #555555;
}
.contact-sales-section {
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 60px 0;
  background-image: linear-gradient(
    135deg,
    rgb(215, 31, 99) 0%,
    rgb(140, 0, 124) 100%
  );
}
.contact-sales-section-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact-sales-content {
  padding-left: 50px;
}
.contact-sales-btn {
  text-align: center;
}
.slider-heading {
  color: #555555;
  font-weight: bold;
}
.carousel {
  max-width: 1200px;
  margin: auto;
  padding-top: 10px;
  margin-top: 30px;
}
.carousel-card-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 0;
}
.roles {
  width: 90%;
  margin: 90px auto;
}
.role-txt {
  font-size: 16px !important;
  color: #333333;
  line-height: 30px;
}
.detail-card {
  min-height: 300px;
  max-width: 340px;
  border-radius: 20px;
  margin: auto !important;
  margin-bottom: 10px !important;
}
.bold {
  font-weight: bold;
}
.white {
  color: #ffffff;
}
.carousel-card {
  width: 550px;
  background-color: #ffffff;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0px 0 1px rgba(10, 10, 10, 0.02);
  color: #757763;
  overflow: hidden;
  position: relative;
  margin-left: auto !important;
}
.slider-card {
  margin: 10px 0;
  height: auto;
}
.crImage {
  max-height: 300px;
  min-height: 250px;
}

@media only screen and (max-width: 768px) {
  .mk-submit-btn button {
    width: 150px;
  }
  .staff-easy-content {
    margin-top: 0;
  }
  .mk-creative {
    top: -50px;
  }
  .mk-why-gig-card-container {
    flex-direction: column;
  }
  .why-gig-card {
    width: 300px;
    height: 300px;
    padding: 20px;
  }
  form,
  .form-title {
    text-align: left;
  }
  .form-mb {
    margin-top: 4px;
  }
  .contact-sales-content {
    padding-left: 0 !important;
  }
  .contact-sales-btn {
    margin-top: 60px;
  }
  .contact-sales-section-container {
    flex-direction: column;
  }
  .carousel-card {
    width: unset;
    max-width: 250px;
  }
  .marketing-section-1 {
    background-color: #f8f8f8;
    background-image: url(../assets/mEllipse1.png), url(../assets/mEllipse2.png),
      url(../assets/mEllipse3.png), url(../assets/mEllipse4.png);
    background-repeat: no-repeat, no-repeat;
    background-position: 0% 3%, 99% 32%, 0% 91%, 57px 100%;
  }
}
// @media only screen and (width: 1023px){
//  .mk-form-section{
//    display: flex;
//     align-items: center;
//     justify-content: center;
//  }
//  .mk-creative{
//    width: 50%;
//    top: -85px;
//    left: -206px;
//  }
//  .marketing-section-1{
//    background-position: 0% 3%, 92% 32%, 11% 82%, 130px 100%;
//  }

// }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  /* For landscape layouts only */
  .mk-form-section {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mk-creative {
    width: 50%;
    top: -85px;
    left: -206px;
  }
  .marketing-section-1 {
    background-position: 0% 3%, 92% 32%, 11% 82%, 130px 100%;
  }
  .carousel-card {
    width: unset;
    max-width: 270px;
  }
}
@media only screen and (min-width: 1400px) {
  .mk-creative {
    left: -2px;
  }
}
// @media only screen and (max-device-width: 1024px){
//   .marketing-section-1{
//     background-position: -16% 3%, 95% 25%, 19% 89%, 51% 80%;
//   }
// }

.custom-spacing {
  margin-bottom: 1.4rem;
}
</style>