<template>
<div class="container fullScreen">
  <div class="centerAlign">
    <img v-if="loader" style="max-width: 40% !important;" src="../assets/loader.gif" />
    <div class="is-size-3" v-if="status == 'completed'" style="margin-top: 20%">
      Verification successfully completed !!
    </div>
    <div class="is-size-3" v-if="status == 'failed'" style="margin-top: 20%">
      Failed to verify !!
    </div>
    <button
      style="margin-top: 2%"
      v-if="status == 'failed'"
      @click="close()"
      class="button is-primary is-medium"
    >
      close
    </button>
    <button
      style="margin-top: 2%"
      v-if="status == 'completed'"
      @click="successClose()"
      class="button is-primary is-medium"
    >
      close
    </button>
  </div>
</div>
</template>
<script>
import { verifyKYC } from "../service/webservice";

export default {
  data: () => ({
    loader: false,
    status: "pending",
  }),
  methods: {
    async verifyUser(userId, tranId) {
      this.loader = true;
      try {
        let resp = await verifyKYC(userId, tranId);
        this.loader = false;
        if (resp.status) {
          this.status = "completed";
        } else {
          this.status = "failed";
        }
      } catch (e) {
        this.loader = false;
        this.status = "failed";
      }
    },
    successClose() {
      window.Android.sendData(JSON.stringify({
        status: true,
        message: "verified successfully !!",
      }));
    },
    close() {
      window.Android.sendData(JSON.stringify({
        status: false,
        message: "Failed to verify",
        logs: `failed to verify user`,
      }));
    },
  },
  mounted() {
    let userId = this.$route.params.id;
    let tranId = this.$route.params.tid;
    this.verifyUser(userId, tranId);
  },
};
</script>
<style scoped>
.fullScreen {
    height: 100vh;
    width: 100vw;
}
.centerAlign {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 250px;
    height: 100px;
}
</style>