<template>
  <div class="main-container">
    <div class="mk-container">
      <myMkHeader></myMkHeader>

      <h1 class="display--1 mar--t--60 has-text-weight-semibold">Media</h1>
      <div class="media-row">
        <div v-for="(e, i) in cardData" :key="i" @click="sendData(e)" class="media-card">
          <a class="mlink" :href="e.link" target="_blank">
            <div class="media-link">
              <div class="media-image-column">
                <div class="media-image-container">
                  <div>
                    <img class="media-image" alt="" :src="e.img" />
                  </div>
                </div>
                <div class="media-logo-container">
                  <img :src="e.companyLogo" alt="" class="yourStory" />
                </div>
                <div>
                  <h1 class="media-title media-card-heading">
                    {{ e.heading }}
                  </h1>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
      <myFooter></myFooter>
    </div>
  </div>
</template>
<script>
import footer from "../components/Footer";
import Mkheader from "../components/MarketingHeader";
import cardData from "../constants/cardData";

export default {
  components: {
    myFooter: footer,
    myMkHeader: Mkheader,
  },
  data: () => ({
    cardData,
    selectedCard: {},
  }),
  methods: {
    
     sendData(e) {
      this.selectedCard = e;
      // this.$router.push({name: "MediaPage", params:{data:this.selectedCard}})
    }
  },
};
</script>
<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

.main-container {
  max-width: 1400px;
  min-width: 280px;
  margin: 0 auto;
  background-color: rgba(228, 44, 100, 0.05);
}
.media-row {
  max-width: 1400px;
  min-width: 280px;
  margin: 0 auto;
}
.media-row {
  width: 90%;
  // margin: 80px auto;
  margin: 30px auto;
  display: flex;
  flex-wrap: wrap;
}
.media-card{
  width: 33.3%;
}
.media-image-column {
  // width: 33%;
  width: 100%;
  padding: 24px;
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  cursor: pointer;
}
.media-link {
  width: 100%;
}
.media-image-column:hover {
  box-shadow: 12px 12px 40px 0 rgba(55, 84, 170, 0.1),
    12px 12px 20px 0 rgba(0, 0, 0, 0.1), -12px -12px 80px 0 white,
    inset 4px 4px 20px 0 white;
  transition: box-shadow 310ms ease-in-out, background-image 310ms ease-in-out,
    transform 0.35s;
  transform: scale(1.05);
  background-image: linear-gradient(139deg, #ffffff00 -7%, #f6f6f600 96%);
}
.media-image-container {
  width: 100%;
  height: 225px;
}
.media-image-container img {
  border-radius: 10px;
  // width: auto;
  width: 100%;
  height: 225px;
  object-fit: cover;
}
.media-logo-container {
  display: flex;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 12px;
  height: 50px;
}
.media-title {
  margin-top: 10px;
  text-align: left;
}
.VcCircle,
.inc42-logo,
.yourStory {
  width: 100px;
  height: 100px;
}

@media only screen and (max-width: 768px) {
  .media-row {
    flex-direction: column;
  }
  .media-card{
    width: 100%;
  }
  .media-image-column {
    width: 100%;
    margin-top: 16px;
  }
  .media-image-container img{
    width: 100%;
  }
}
</style>