import yourstoryimage from "../assets/yourstory-axtria-funding.png";
import yourstorylogo from "../assets/logo_yourstory.svg";
import inc42logo from "../assets/inc42-sticky-logo.svg";
import vcclogo from "../assets/VCC-logo.svg";
import vccimage from "../assets/InvestSJ.jpg";
import FactsheetImage from "../assets/FactsheetImage.jpg";
import FactsheetImage2020 from "../assets/FactsheetImage_2020.jpg";


export default [
    {
        img: yourstoryimage,
        companyLogo: yourstorylogo,
        heading: "Staffing solution startup Gigforce raises Rs 6 Cr from Unitus Ventures",
        date: "22nd Jul 2020",
        link: "https://yourstory.com/2020/07/funding-staffing-solution-startup-gigforce-unitus-ventures?utm_pageloadtype=scroll",
        description: [
            {
                content: `Bengaluru-based tech-enabled staffing solution startup Gigforce has raised Rs 6 crore from Unitus Ventures. Surya Mantha, Senior Partner, Unitus Ventures said`,
                font: 'normal'
            },
            {
                content: `“In India, almost 70 percent of corporates have already used gig workers for at least one task in 2018. We are glad to partner with Gigforce, and we strongly believe that Gigforce’s value proposition will be core to the future of staffing. This will be a game changer while growing the global gig market multifold, in turn, creating more employment opportunities in emerging economies like India.”`,
                font: 'bold'
            },
            {
                content: `Founded by Chirag Mittal (CEO), Parag Modi (CTO), and Anirudh Syal (COO), Gigforce is redefining staffing solutions for businesses that rely on gig workers for their jobs. The startup bridges the gap between conventional hiring and modern-day business demands.`,
                font: 'normal'
            },
            {
                content: `Today, businesses are not only looking for on-demand staff, but also for business focused solutions.  Parameters like the flexibility of contracts, quick onboarding, pre-trained staff, replacement of inadequately trained talent and more transparency, are increasingly becoming more important.`,
                font: 'normal'
            },
            {
                content: `For gig workers, the platform offers the opportunity to select jobs suited to their priorities, along with industry specific training and upskilling.`,
                font: 'bold'
            },
            // {
            //     content: `Chirag Mittal, Co-founder and CEO, Gigforce added`,
            //     font: 'normal'
            // },
            // {
            //     content: `"The dynamics of hiring have rapidly changed in the last six months. We have witnessed how the ongoing crisis has affected the growth of businesses and their staff. To ensure that a similar situation is avoided in future, Gigforce aims to reduce operational hassle for businesses and provide meaningful ways to earn a livelihood for workers. We believe that a large number of retail players will want to move to this model, especially in these times where the demand is uncertain and the economy is subject to frequent disruptions.”`,
            //     font: 'bold'
            // },
            {
                content: `Parag Modi, Co-founder and CTO, Gigforce shared that the company uses chatbots, mobile applications, self-managed dashboards and a matching engine in the background to create the complete service. Gigforce also integrates and uses social media platforms like Facebook and WhatsApp to quickly onboard workers seeking earning opportunities, he stated.`,
                font: 'normal'
            },
            {
                content: `According to the startup, In India, this market is estimated to be $10 billion and is expected to grow to $40 billion in a few years.`,
                font: 'normal'
            },
            {
                content: `“Demand is not a concern as enterprises have shown interest in flexible hiring solutions. A testimony to this is that we have already received more pilot requests than predicted, which is very encouraging. The aim is to ensure service and scalability for clients,” shared Anirudh Syal, Co-founder and COO, Gigforce.`,
                font: 'bold'
            }
        ]

    },
    {
        img: vccimage,
        companyLogo: vcclogo,
        heading: "Unitus Ventures backs staffing startup co-founded by TeamLease execs",
        date: "08 July, 2020",
        link: "https://www.vccircle.com/unitus-ventures-backs-staffing-startup-co-founded-by-teamlease-execs/",
        description: [
            {
                content: `Bengaluru-based tech-enabled staffing solution startup Gigforce has raised Rs 6 crore from Unitus Ventures. Surya Mantha, Senior Partner, Unitus Ventures said`,
                font: 'normal'
            },
            {
                content: `“In India, almost 70 percent of corporates have already used gig workers for at least one task in 2018. We are glad to partner with Gigforce, and we strongly believe that Gigforce’s value proposition will be core to the future of staffing. This will be a game changer while growing the global gig market multifold, in turn, creating more employment opportunities in emerging economies like India.”`,
                font: 'bold'
            },
            {
                content: `Founded by Chirag Mittal (CEO), Parag Modi (CTO), and Anirudh Syal (COO), Gigforce is redefining staffing solutions for businesses that rely on gig workers for their jobs. The startup bridges the gap between conventional hiring and modern-day business demands.`,
                font: 'normal'
            },
            {
                content: `Today, businesses are not only looking for on-demand staff, but also for business focused solutions.  Parameters like the flexibility of contracts, quick onboarding, pre-trained staff, replacement of inadequately trained talent and more transparency, are increasingly becoming more important.`,
                font: 'normal'
            },
            {
                content: `For gig workers, the platform offers the opportunity to select jobs suited to their priorities, along with industry specific training and upskilling.`,
                font: 'bold'
            },
            {
                content: `Chirag Mittal, Co-founder and CEO, Gigforce added,`,
                font: 'normal'
            },
            {
                content: `“The dynamics of hiring have rapidly changed in the last six months. We have witnessed how the ongoing crisis has affected the growth of businesses and their staff. To ensure that a similar situation is avoided in future, Gigforce aims to reduce operational hassle for businesses and provide meaningful ways to earn a livelihood for workers. We believe that a large number of retail players will want to move to this model, especially in these times where the demand is uncertain and the economy is subject to frequent disruptions.”`,
                font: 'bold'
            },
            {
                content: `Parag Modi, Co-founder and CTO, Gigforce shared that the company uses chatbots, mobile applications, self-managed dashboards and a matching engine in the background to create the complete service. Gigforce also integrates and uses social media platforms like Facebook and WhatsApp to quickly onboard workers seeking earning opportunities, he stated.`,
                font: 'normal'
            },
            {
                content: `According to the startup, In India, this market is estimated to be $10 billion and is expected to grow to $40 billion in a few years.`,
                font: 'normal'
            },
            {
                content: `“Demand is not a concern as enterprises have shown interest in flexible hiring solutions. A testimony to this is that we have already received more pilot requests than predicted, which is very encouraging. The aim is to ensure service and scalability for clients,” shared Anirudh Syal, Co-founder and COO, Gigforce.`,
                font: 'bold'
            }
        ]

    },
    {
        img: FactsheetImage,
        companyLogo: inc42logo,
        heading: "30 Startups To Watch: The Startups That Caught Our Eye In July 2020",
        date: `01 Aug'20`,
        link: "https://inc42.com/features/30-startups-to-watch-the-startups-that-caught-our-eye-in-july-2020/",
        description: [
            {
                content: `Bengaluru-based tech-enabled staffing solution startup Gigforce has raised Rs 6 crore from Unitus Ventures. Surya Mantha, Senior Partner, Unitus Ventures said`,
                font: 'normal'
            },
            {
                content: `“In India, almost 70 percent of corporates have already used gig workers for at least one task in 2018. We are glad to partner with Gigforce, and we strongly believe that Gigforce’s value proposition will be core to the future of staffing. This will be a game changer while growing the global gig market multifold, in turn, creating more employment opportunities in emerging economies like India.”`,
                font: 'bold'
            },
            {
                content: `Founded by Chirag Mittal (CEO), Parag Modi (CTO), and Anirudh Syal (COO), Gigforce is redefining staffing solutions for businesses that rely on gig workers for their jobs. The startup bridges the gap between conventional hiring and modern-day business demands.`,
                font: 'normal'
            },
            {
                content: `Today, businesses are not only looking for on-demand staff, but also for business focused solutions.  Parameters like the flexibility of contracts, quick onboarding, pre-trained staff, replacement of inadequately trained talent and more transparency, are increasingly becoming more important.`,
                font: 'normal'
            },
            {
                content: `For gig workers, the platform offers the opportunity to select jobs suited to their priorities, along with industry specific training and upskilling.`,
                font: 'bold'
            },
            {
                content: `Chirag Mittal, Co-founder and CEO, Gigforce added,`,
                font: 'normal'
            },
            {
                content: `“The dynamics of hiring have rapidly changed in the last six months. We have witnessed how the ongoing crisis has affected the growth of businesses and their staff. To ensure that a similar situation is avoided in future, Gigforce aims to reduce operational hassle for businesses and provide meaningful ways to earn a livelihood for workers. We believe that a large number of retail players will want to move to this model, especially in these times where the demand is uncertain and the economy is subject to frequent disruptions.”`,
                font: 'bold'
            },
            {
                content: `Parag Modi, Co-founder and CTO, Gigforce shared that the company uses chatbots, mobile applications, self-managed dashboards and a matching engine in the background to create the complete service. Gigforce also integrates and uses social media platforms like Facebook and WhatsApp to quickly onboard workers seeking earning opportunities, he stated.`,
                font: 'normal'
            },
            {
                content: `According to the startup, In India, this market is estimated to be $10 billion and is expected to grow to $40 billion in a few years.`,
                font: 'normal'
            },
            {
                content: `“Demand is not a concern as enterprises have shown interest in flexible hiring solutions. A testimony to this is that we have already received more pilot requests than predicted, which is very encouraging. The aim is to ensure service and scalability for clients,” shared Anirudh Syal, Co-founder and COO, Gigforce.`,
                font: 'bold'
            }
        ]
    },
    {
        img: FactsheetImage2020,
        companyLogo: inc42logo,
        heading: "30 Startups To Watch: The Startups That Caught Our Eye In 2020",
        date: `31 Dec'20`,
        link: "https://inc42.com/features/30-startups-to-watch-the-startups-that-caught-our-eye-in-2020/?utm_medium=email&utm_source=mail-merge&utm_campaign=plus-member-exclusive&utm_content=30-startups-dec&utm_term=featured-startups",
        description: [
            {
                content: `Bengaluru-based tech-enabled staffing solution startup Gigforce has raised Rs 6 crore from Unitus Ventures. Surya Mantha, Senior Partner, Unitus Ventures said`,
                font: 'normal'
            },
            {
                content: `“In India, almost 70 percent of corporates have already used gig workers for at least one task in 2018. We are glad to partner with Gigforce, and we strongly believe that Gigforce’s value proposition will be core to the future of staffing. This will be a game changer while growing the global gig market multifold, in turn, creating more employment opportunities in emerging economies like India.”`,
                font: 'bold'
            },
            {
                content: `Founded by Chirag Mittal (CEO), Parag Modi (CTO), and Anirudh Syal (COO), Gigforce is redefining staffing solutions for businesses that rely on gig workers for their jobs. The startup bridges the gap between conventional hiring and modern-day business demands.`,
                font: 'normal'
            },
            {
                content: `Today, businesses are not only looking for on-demand staff, but also for business focused solutions.  Parameters like the flexibility of contracts, quick onboarding, pre-trained staff, replacement of inadequately trained talent and more transparency, are increasingly becoming more important.`,
                font: 'normal'
            },
            {
                content: `For gig workers, the platform offers the opportunity to select jobs suited to their priorities, along with industry specific training and upskilling.`,
                font: 'bold'
            },
            {
                content: `Chirag Mittal, Co-founder and CEO, Gigforce added,`,
                font: 'normal'
            },
            {
                content: `“The dynamics of hiring have rapidly changed in the last six months. We have witnessed how the ongoing crisis has affected the growth of businesses and their staff. To ensure that a similar situation is avoided in future, Gigforce aims to reduce operational hassle for businesses and provide meaningful ways to earn a livelihood for workers. We believe that a large number of retail players will want to move to this model, especially in these times where the demand is uncertain and the economy is subject to frequent disruptions.”`,
                font: 'bold'
            },
            {
                content: `Parag Modi, Co-founder and CTO, Gigforce shared that the company uses chatbots, mobile applications, self-managed dashboards and a matching engine in the background to create the complete service. Gigforce also integrates and uses social media platforms like Facebook and WhatsApp to quickly onboard workers seeking earning opportunities, he stated.`,
                font: 'normal'
            },
            {
                content: `According to the startup, In India, this market is estimated to be $10 billion and is expected to grow to $40 billion in a few years.`,
                font: 'normal'
            },
            {
                content: `“Demand is not a concern as enterprises have shown interest in flexible hiring solutions. A testimony to this is that we have already received more pilot requests than predicted, which is very encouraging. The aim is to ensure service and scalability for clients,” shared Anirudh Syal, Co-founder and COO, Gigforce.`,
                font: 'bold'
            }
        ]
    }

]