<template>
  <div class="container fullScreen">
      <div class="centerAlign">
    <img v-if="loader" style="max-width: 40% !important;" src="../assets/loader.gif">
    <div class="is-size-3" v-if="status == 'failed'" style="margin-top:20%">
        Failed to verify !!
    </div>
    <button
      style="margin-top:2%"
      v-if="status == 'failed'"
      @click="close()"
      class="button is-primary is-medium"
    >
      close
    </button>
  </div>
  </div>
</template>
<script>
import {createKYCTransaction} from '../service/webservice'

 export default {
     data : () =>({
         loader :  false ,
         status :  'pending'
     }),
     methods : {
         close() {
            window.Android.sendData(JSON.stringify({status : false , message : 'Failed to verify',logs :`failed to create a transaction`}));  
         } ,
         async createTransaction(userId){
                this.loader =  true
                try {
                    let resp =  await createKYCTransaction(userId)
                this.loader  = false
                if(resp.status){
                    window.location.href = resp.kycUrl
                } else {
                    this.status = 'failed'
                }
                } catch(e){
                    this.loader =  false
                    this.status = 'failed'
                }
                
         }
     } ,
     mounted() {
          let userId = this.$route.params.id;
          this.createTransaction(userId)
     }
 }
//  var Android;// eslint-disable-line no-unused-vars
</script>
<style scoped>
    .fullScreen {
        height: 100vh;
        width: 100vw;
    }
    .centerAlign {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 250px;
        height: 100px;
    }
</style>