<template>
  <div class="home">
    <my-header></my-header>
    <section id="option-area" class="mar--t">
      <div class="interest-container is-vcentered">
        <div class="interest-box-1 margin--r--1">
          <div class="text-left ml-10 mar--l--0">
            <div class="title--1">I want to earn</div>
            <hr />
          </div>

          <div class="row">
            <img src="../assets/earn.png" width="100" alt="earn" />
            <div class="text-left display--3">
              Tell us your preferences. We will help you learn necessary skills
              and match with gigs.
            </div>
          </div>

          <div class="py-4">
            <button
              class="button is-primary is-medium"
              v-scroll-to="'#earnForm'"
            >
              Interested
            </button>
          </div>
        </div>
        <div class="interest-box-2 mar--t">
          <div class="text-left ml-10 mar--l--0">
            <div class="title--1">I want to hire</div>
            <hr />
          </div>

          <div class="row">
            <img src="../assets/collabrate.png" width="100" alt="collabrate" />
            <div class="text-left display--3">
              Let’s know you are interested. We will get in touch with you to
              tailor a gig program for you.
            </div>
          </div>
          <div class="py-4">
            <button
              class="button is-primary is-medium"
              v-scroll-to="'#hireForm'"
            >
              Interested
            </button>
          </div>
        </div>
      </div>
    </section>

    <section id="earnForm">
      <div class="earnform-container is-vcentered">
        <div class="text-left form-col">
          <div class="mb-2 title--1">I want to earn</div>
          <hr />
          <div class="preference mb-2 display--3">
            Tell us your preferences. We will help you learn necessary skills
            and match with gigs.
          </div>
          <form class="earning-form" @submit.prevent="submitEarning">
            <div class="control form-mb">
              <label>Name</label>
              <div class="control">
                <input
                  class="input"
                  v-model="earningForm.name"
                  type="text"
                  placeholder="e.g Alex Smith"
                  required
                />
              </div>
            </div>

            <div class="control form-mb">
              <label>Email</label>
              <div class="control">
                <input
                  v-model="earningForm.email"
                  class="input"
                  type="email"
                  placeholder="e.g. youremail@domain.com"
                  required
                />
              </div>
            </div>
            <div class="control form-mb">
              <label>Contact No</label>
              <div class="control">
                <input
                  v-model="earningForm.mobile"
                  class="input"
                  type="number"
                  placeholder="e.g. +91-98xxxxxx89"
                  required
                />
              </div>
            </div>
            <div class="control form-mb">
              <label>Current City</label>
              <div class="control">
                <input
                  v-model="earningForm.currentCity"
                  class="input"
                  type="text"
                  placeholder="Current City"
                  required
                />
              </div>
            </div>
            <div class="control form-mb">
              <label>Current Status</label>
              <div class="control">
                <div class="select is-medium">
                  <select v-model="earningForm.status" required>
                    <option value="studing">Studying</option>
                    <option value="working">Working</option>
                    <option value="looking-for-work">Looking for work</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="control form-mb">
              <label>I'm looking for</label>
              <div class="control">
                <div class="select is-medium">
                  <select v-model="earningForm.lookingFor" required>
                    <option value="part-time">Part time gigs</option>
                    <option value="remote-gig">Full time gigs</option>
                    <option value="remote-gig">Remote gigs</option>
                    <option value="flexible-time">Flexible time gigs</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="form-mb">
              <label>Message</label>
              <div class="control">
                <textarea
                  v-model="earningForm.message"
                  class="textarea"
                  placeholder="Write your message here"
                  required
                  rows="3"
                ></textarea>
              </div>
            </div>

            <div class="file has-name mt-3">
              <label class="file-label">
                <input class="file-input" type="file" 
                       @change="filePickerChange"
                       name="resume" />
                <span class="file-cta">
                  <span class="file-icon">
                    <i class="fas fa-upload"></i>
                  </span>
                  <span class="file-label">Upload Resume </span>
                </span>
                <span class="file-name">
                  {{ file ? file.name : 'Select file' }}
                </span>
              </label>
            </div>
            <div class="py-4">
              <button type="submit" class="button is-primary">Submit</button>
            </div>
          </form>
        </div>
        <div class="column is-offset-3">
          <img class="creative" alt="" src="../assets/Group-1941.png" />
        </div>
      </div>
    </section>

    <section id="hireForm">
      <div class="columns is-vcentered">
        <div class="column is-6 mb-4">
          <img class="creative" alt="" src="../assets/Group-1945.png" />
        </div>
        <div class="column pl-form is-6 text-left">
          <div class="title--1">I want to hire</div>
          <hr />
          <div class="display--3">
            Let’s know you are interested. We will get in touch with you to
            tailor a gig program for you.
          </div>
          <form @submit.prevent="submitHiring">
            <div class="control form-mb">
              <label>Name</label>
              <div class="control">
                <input
                  class="input"
                  v-model="hiringForm.name"
                  type="text"
                  placeholder="e.g Alex Smith"
                  required
                />
              </div>
            </div>

            <div class="control form-mb">
              <label>Email</label>
              <div class="control">
                <input
                  class="input"
                  v-model="hiringForm.email"
                  type="email"
                  placeholder="e.g. youremail@domain.com"
                  required
                />
              </div>
            </div>
            <div class="control form-mb">
              <label>Contact No</label>
              <div class="control">
                <input
                  v-model="hiringForm.contactNo"
                  class="input"
                  type="number"
                  placeholder="e.g. +91-98xxxxxx89"
                  required
                />
              </div>
            </div>
            <div class="control form-mb">
              <label>Business Name</label>
              <div class="control">
                <input
                  v-model="hiringForm.businessName"
                  class="input"
                  type="text"
                  placeholder="Business Name"
                  required
                />
              </div>
            </div>
            <div>
              <label>Message</label>
              <div class="control form-mb">
                <textarea
                  v-model="hiringForm.message"
                  class="textarea"
                  placeholder="Write your message here"
                  required
                ></textarea>
              </div>
            </div>

            <div class="py-4">
              <button type="submit" class="button is-primary">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </section>

    <my-footer></my-footer>
  </div>
</template>
<script>
import header from "../components/Header";
import footer from "../components/Footer";
import { submitForm , earningForm } from "../service/webservice";

export default {
  components: {
    myFooter: footer,
    myHeader: header,
  },
  data: () => ({
    earningForm: {},
    hiringForm: {},
    file : null
  }),
  methods: {
    submitEarning() {
      if(!this.file){
          alert(`Please select resume`)
          return
      }
      const fd = new FormData()

      fd.append('data',JSON.stringify(this.earningForm))
      fd.append('file',this.file)
      earningForm(fd, "Earning")
        .then((suc) => {
          alert(suc.data);
          this.earningForm = {};
        })
        .catch((err) => {
          alert(`Oops...some error`);
          console.log(err);
        });
    },
    submitHiring() {
      submitForm(this.hiringForm, "Hiring")
        .then((suc) => {
          alert(suc.data);
          this.hiringForm = {};
        })
        .catch((err) => {
          alert(`Oops...some error`);
          console.log(err);
        });
    },
    filePickerChange(e){
        this.file = e.target.files[0]
    }
  },
};
</script>
<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

#option-area {
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;

  .column {
    margin-left: 25px;
  }
  .ml-10 {
    margin-left: 100px;
  }
  hr {
    margin-top: 2px;
    width: 130px;
    height: 3px;
    background-color: #d71f63;
  }

  .row {
    display: flex;
    justify-content: flex-start;
  }
}

#earnForm {
  min-height: 500px;
  font-family: "Poppins", sans-serif;
  .text-col {
    margin-left: 5rem;
  }
  .form-col {
    margin-top: 5rem;
    margin-left: 5rem;
    max-width: 400px;
  }
  hr {
    margin-top: 2px;
    width: 130px;
    height: 3px;
    background-color: #d71f63;
  }
  .column {
    padding: 0;
  }

  .creative {
    position: relative;
    right: 0;
  }
}

#hireForm {
  min-height: 500px;
  font-family: "Poppins", sans-serif;

  hr {
    margin-top: 2px;
    width: 130px;
    height: 3px;
    background-color: #d71f63;
  }
  .column {
    padding: 0;
  }
  .pl-form {
    max-width: 450px !important;
    padding: 0rem;
    margin-left: 80px;
  }
  .creative {
    position: relative;
    left: 0;
  }
}

.input,
.textarea,
.select select {
  border: 1px solid #cccccc;
}
.interest-container {
  display: flex;
  justify-content: center;
  width: 70%;
  margin: 0 auto;
}
.earnform-container {
  width: 103%;
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 768px) {
  .row {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .interest-container {
    flex-direction: column;
    margin-top: 10%;
  }
  .earnform-container {
    flex-direction: column-reverse;
    transform: translate(12px, 10px);
  }
  .form-col {
    margin-left: 0 !important;
    margin-top: 0 !important ;
    padding: 14px;
  }
  .earning-form {
    width: 90%;
  }
  .pl-form {
    padding: 2rem !important;
    margin-left: 16px !important;
  }
}
</style>