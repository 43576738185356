<template>
  <div>
    <!-- <my-header></my-header> -->
    <div class="container top-margin">
      <div class="is-size-2">Terms & Conditions</div>
      <div class="text-left">
         <ol type="I">
        <b><li>Recitals</li></b>
        <br/>
        <ol>
            <li>This document is an electronic record in terms of Information Technology Act, 2000 and rules there under as applicable and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology (Amendment) Act, 2008. This electronic record is generated by a computer system and does not require any physical or digital signatures. This electronic record is generated by a computer system and does not require any physical or digital signatures.</li>
            <br/><li>This document is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries Guidelines) Rules, 2011 that require publishing the rules and regulations, privacy policy and user agreement for access or usage of www.gig4ce.com <b>[SAM & Co. Notes: Please confirm the domain name]</b></li>
            <br/><li>www.gig4ce.com (“<b>Website</b>”) and our application gig4ce (jointly referred to as “<b>Platform</b>”) is owned and operated by <b>Gigforce Private Limited</b>, a company incorporated under the Companies Act, 2013 vide CIN - U74999HR2019PTC082217, having its corporate office at House no 1872 Sector-2 BALLABHGARH Faridabad HR 121004 IN <b>[SAM& Co. Note: Please confirm the same]</b> hereinafter referred to as the “<b>Company</b>” (which expression shall, unless it be repugnant to the context or meaning thereof, include its successors and assigns).</li>
            <br/><li>Subject to the terms and conditions of this Agreement (as defined hereinafter), the Company shall make available through the Platform being an online aggregator platform and connecting medium for (i) persons to provide their work profiles, availability status and interest areas for rendering services in specific areas in time bound manner (“<b>Independent Consultants</b>” or “<b>IC</b>”); (ii) for corporate entities to provide their requirements of availing services of IC’s on specific assignments (“<b>Work Assignments</b>”) in regard to their business establishment (“<b>Requirement Listing Companies</b>”); (iii) third party service provider entities who are interested in rendering services including skill improvement seminars, e-workshops for enhancing professional skills, preparation of IC’s for interview and finalising of their work profile, insurances and social security, payroll services, pay roll compliance services, staffing service providers, legal and account related services, credit score checks and background checks  etc. (“<b>Third Party Service Providers</b>”) making available the information being uploaded by IC, Requirement Listing Companies and Third Party Service Providers and enabling parties to have better access to services and/or service providers in consideration of the subscription fee which may be fixed by the Company from time to time and reflected on the Platform while availing the services and as may be charged by the Company at its sole discretion from time to time (“<b>Subscription Fees</b>”) on the terms and conditions set out herein (“<b>Services</b>”). </li>
            <br/><li>It is expressly clarified that the Company may render certain specific Services without any charges for a specific time period as may be deemed fit by the Company and which shall be notified on the Platform from time to time. It is expressly stated that the User shall be solely responsible and liable for the information, images and data uploaded by the User.. The Company has the absolute right, at any time, to render the Services being offered through the Platform through any party whatsoever.</li>
            <br/><li>Use of this Platform is regulated by terms and conditions provided herein. By clicking on the “<b>I ACCEPT</b>” button, you are consenting to be bound by these Agreement and please ensure that you read and understand all these user terms before you use the Platform. If you do not accept any of the user terms, then please do not use the site or avail any of the services being provided therein. Your agreement to these user terms shall operate as a binding agreement between you and the Company in respect of the use and services of the Platform. Your access, visit, dealing, transacting and/or otherwise using this Platform shall be treated as your unconditional acceptance of these terms in entirety and the same shall be deemed that you have accepted these terms fully and you have gone through and understood these terms completely and these terms shall be treated as a legally binding and enforceable Agreement between the Company and you (“<b>Acceptance</b>”). If you do not agree with any of these terms or all of these terms, then you should stop dealing with and/or not initiate or undertake any transaction on this Platform. </li>
            <br/><li>The information and Services offered on this Platform or through other mediums should not be considered as a substitute for professional advice. The User's use of the Platform and/or Services is subject to the additional disclaimers and caveats that may appear throughout the Platform. The Company, its associate companies, subsidiaries assume no responsibility for any consequence relating directly and/or indirectly to any action and/or inaction that the User takes based on the information, Services and other materials on this Platform. While the Company strives to keep the information relating to the various Services offered on this Platform accurate, complete and up-to-date, the Company, its subsidiaries, affiliates, associate companies, consultants, partners, agents and suppliers cannot guarantee, and will not be responsible for any damage and/or loss related to, the accuracy, completeness or timelines of the information. <br/><br/> It is expressly provided that the data pertaining to the Services offered through the Platform is provided by third parties and the same is not owned by the Company.</li>
            <br/><li>The Company may change, suspend and/or discontinue the Services at any time, including the availability of any feature, database and content on the Platform. The Company may also impose limits on certain features and Services and/or restrict the User's access to parts and/or all of the Services without notice and/or liability to the Users.</li>
            <br/><li>All kind of correspondence should be addressed to Company’s office address as given above.</li>
        </ol>
        <br/>  
        <b><li>TERMS AND CONDITIONS</li></b>
        <br/>
        <ol>
            <b><li>Definitions</li></b>
            <p>The words and phrases used in these terms are defined as under unless repugnant to the context or meaning thereof:</p>
            <p>“<b>Acceptance</b>” shall have the meaning ascribed to it in Recital I (5).</p>
            <p>“<b>Agreement</b>” shall mean this agreement between the Company and the User with terms and conditions as provided herein and includes the Privacy Policy and all schedules, appendices and references mentioned herein with all such amendments as effected by the Company from time to time.</p>
            <p>“<b>Company</b>” shall have the meaning ascribed to it in Recital I (3).</p>
            <p>“<b>Independent Consultant</b>” or “IC” shall have the meaning ascribed to it in Recital I (4).</p>
            <p>“<b>Intellectual Property Rights</b>” shall have the meaning ascribed to it in Clause 4.2(a).</p>
            <p>“<b>Marks</b>” shall have the meaning ascribed to it in Clause 4.2(e).</p>
            <p>“<b>Platform</b>” shall have the meaning ascribed to it in Recital I (3).</p>
            <p>“<b>Requirement Listing Companies</b>” shall have the meaning ascribed to it in Recital I (4).</p>
            <p>“<b>Subscription Fee</b>" shall have the meaning ascribed to it in Recital I (4).</p>
            <p>“<b>Services</b>” shall have the meaning ascribed to it in Recital I (4).</p>
            <p>“<b>Third Party Content</b>” shall have the meaning ascribed to it in Clause 5.1.</p>
            <p>“<b>Third Party Service Provider</b>” shall have the meaning ascribed to it in Recital I (4).</p>
            <p>“<b>User</b>” shall mean any person including IC and/or an authorized personnel of a legal entity including Requirement Listing Companies, Third Party Service Providers that visits, uses, deals with and/or transacts on the Platform in any way. Any references herein to 'you' and 'your' shall mean the User.</p>
            <p>“<b>ser Content</b>” shall have the meaning ascribed to it in Clause 5.3</p>
            <p>“<b>Platform</b>” shall have the meaning ascribed to it in Recital I (3).</p>
            <p>“<b>Work Assignments</b>” shall have the meaning ascribed to it in Recital I (4).</p>
            <p>“<b>Web Links</b>” shall have the meaning ascribed to it in Clause XI.</p>
            <p>In this Agreement, the ‘<b>Company</b>’ and the ‘<b>User</b>’ have been collectively referred to as “<b>Parties</b>” and individually as “<b>Party</b>”.</p>
            
            <b><li>Eligibility</li></b>
            <div style="display: flex;"><p>2.1</p><p style="margin-left: 10px;">The User hereby represents and warrants that the User is atleast Eighteen (18) years of age and the User’s visit, access, dealing and/or transaction at the Platform shall be treated as the User’s representation that the User is competent to enter into legal contracts under applicable law including the Indian Contract Act, 1872.</p></div>
            <div style="display: flex;"><p>2.2</p><p style="margin-left: 10px;">If the User is availing of the Services, the User further warrants that the User has legal authority to enter into legally binding agreements for the same. The User agrees to be financially responsible for all of the User’s use of the Services and access of the Platform (as well as for use of the User’s account by others). The User also warrants that all information supplied by the User in availing the Services and accessing the Platform, including without limitation the Users Content, the User’s name, email address, street address, telephone number, mobile number, credit/debit card number and other payment details is correct and accurate. Failure to provide accurate information may subject the User to civil and criminal penalties. This Agreement and/or any of the provision is void by law, and the right to access the Platform is revoked in such jurisdictions.</p></div>
            <div style="display: flex;"><p>2.3</p><p style="margin-left: 10px;">The User acknowledge and agree that the Services being provided through the Platform are only connecting services being an aggregator platform reflecting information uploaded by Users and enabling parties to have better access to services and/or service providers and the Company has not verified the veracity of any information and disclosures being made by such third parties except as set out herein and the inter-connection between the Users shall be governed by terms and conditions agreed between the Users i.e. IC, Requirement Listing Companies and Third Party Service Providers (as may be applicable) and the Company is not a party to such arrangement and accordingly shall not be responsible and/or liable whether financially or otherwise in regard to such inter-connection.</p></div>
            
            <b><li>Amendment of Terms</li></b>
            <p>The Company is entitled to amend these terms anytime without any prior intimation to anybody and simply by way of notification on the Platform. The User shall be responsible for reviewing and becoming familiar with any such modifications/amendments from time to time and shall be bound to this Agreement as amended by the Company from time to time. Use of the Services by the User following such notification constitutes the User’s acceptance of the terms and conditions of this Agreement as modified.</p>
            
            <b><li>Intellectual Property</li></b>
            <p>4.1	<b style="margin-left: 10px;">Platform Content</b></p>
            <div style="display: flex; margin-left: 30px;"><p>(a)</p><p style="margin-left: 10px;">The User acknowledges and agrees that the Company retains all right, title, ownership and interest in and to the Platform, all data and information, content, articles, material, text, literature, images, illustrations, photographs, videos, clippings, graphics, scripts, page headers, sounds, music, interactive features, the selection and arrangement of elements, the compilation, layout, design, software, domain name, business process, procedures, in any form whatsoever including but not limited to, print, writing, oral, electronic, digital/IT enabled, in machine readable and/or in any other form, and all patent rights, copyright rights, trademark rights, service mark rights, trade secret rights and other intellectual property rights therein recognized anywhere in the world (“Intellectual Property Rights”), other than rights in Third Party Content or User Content. All use of such rights, including the Intellectual Property Rights, in any manner, medium or mode shall perpetually and throughout the world legally vest in the Company at all times.</p></div>
            <div style="display: flex; margin-left: 30px;"><p>(b)</p><p style="margin-left: 10px;">Payment of the Subscription Fee by the User to the Company for a particular Service merely grants the User a non-exclusive, temporary, revocable and conditional license to access and avail the Service for a given period of time.</p></div>
            <div style="display: flex; margin-left: 30px;"><p>(c)</p><p style="margin-left: 10px;">All Intellectual Property Rights of the Company is a valuable asset of the Company and the User agrees to protect, preserve and maintain confidentiality of the same. The User acknowledges that any unauthorized dissemination of the Intellectual Property Rights of the Company would destroy or diminish its value and cause irreparable harm and loss to the Company, and that monetary damages will be inadequate to compensate the Company for any breach thereof. The User, therefore, agrees that the Company shall, in addition to its rights in law and equity including to its right to claim damages, have the right to seek immediate injunctive relief and specific performance to enforce the obligations contained herein.</p></div>
            <div style="display: flex; margin-left: 30px;"><p>(d)</p><p style="margin-left: 10px;">The Platform is protected by copyright as a collective work and/or compilation, pursuant to Indian Copyright laws, international conventions, and other worldwide copyright laws. The User is not authorized to reproduce, republish, distribute, transmit, modify, edit, sell, transfer, license, translate, communicate, publish, use or exploit the Platform and/or its content in any manner whatsoever, or create derivative works based on it, in whole or in part, without the Company’s express written authorization, which the Company is under no obligation to grant.</p></div>
            <div style="display: flex; margin-left: 30px;"><p>(e)</p><p style="margin-left: 10px;">Any logo, trademark, brand name, service mark, trade name, slogan, and other indicia (“<b>Marks</b>”) that appear on or in connection with the Platform and/or its content, is the intellectual property of the Company. The Company expressly reserves all rights therein and the User agrees not to display, use or exploit the same in any manner whatsoever. Nothing contained in this Platform should be construed as granting, by implication, estoppels, or otherwise, any license or right in and to the Marks.</p></div>
            <div style="display: flex; margin-left: 30px;"><p>(f)</p><p style="margin-left: 10px;">The User’s misuse of the Platform and/or its content and/or its Marks, is strictly prohibited. The User is advised that the Company will aggressively enforce its Intellectual Property Rights and/or the contents of the Platform to the fullest extent permissible by law, including by seeking appropriate remedies including criminal sanctions solely at the User’s risk as to costs and consequences. The Company has all the rights to disable or prohibit access to the Users who do not abide by these terms and involve in the infringement of our intellectual property.</p></div>
            <div style="display: flex; margin-left: 30px;"><p>(g)</p><p style="margin-left: 10px;">All the information and content disclosed on the Platform except the Third Party Content and User Content is proprietary in nature and solely belongs to the Company and its respective licensors and shall not be used and/or copied and/or tampered with in any manner (as the case may be).</p></div>
            <div style="display: flex; margin-left: 30px;"><p>(h)</p><p style="margin-left: 10px;">The Users hereby further acknowledge that any views and/or opinions offered by any expert and/or advisory panel on the Platform through any webinars, workshops etc is such expert / advisory panels own view and/or opinion and does not express the Company’s view and / or opinion and the Company shall bear no liability (financial or otherwise) with respect to the same.</p></div>
            <div style="display: flex; margin-left: 30px;"><p>(i)</p><p style="margin-left: 10px;">The Users hereby acknowledge that the Company reserves the right to list its registered Users on the Platform and on other online portals operated by the Company or its affiliates. In case a User does not want to be listed on any such sites or any of the other such online portals, they can communicate the same to the Company in writing by sending an email to [●]. <b>[SAM& Co. Note: Please provide us with the same.]</b></p></div>

            <b><li>Third Party Content and User Content</li></b>
            <div style="display: flex;"><p>5.1</p><p style="margin-left: 10px;">The User hereby acknowledges that there may be publishers, advertisement agencies, other corporates, persons and legal entities who may upload third party trademarks, logos, photographs, images, content and other intellectual property rights on the Platform (“<b>Third Party Content</b>”). The Company shall not be responsible and/or liable for any Third Party Content.</p></div>
            <div style="display: flex;"><p>5.2</p><p style="margin-left: 10px;">The  Users hereby agrees and acknowledges that the Third Party Content are the properties of the respective third parties and the same may be protected by applicable copyright, trademark or other intellectual property laws and treaties and each such third party expressly reserves all the rights to such intellectual property.</p></div>
            <div style="display: flex;"><p>5.3</p><p style="margin-left: 10px;">The Users such as IC, Requirement Listing Companies and the Third Party Service Providers shall be uploading images, data and other information owned or licensed to such Users on the Platform as part of the Services rendered through the Platform (“User Content”). The User hereby represents and warrants that the User Content is owned by such User or the User has the license to use such User Content and upload the same on the Platform. Further, User uploading such User Content hereby acknowledge that the same is being done at the sole discretion and risk of the User and if such User Content is collected by a third party using the Platform which is misused or results in unsolicited messages from such third parties, then such actions would be beyond the control of the Company and the Company accepts no responsibility or liability whatsoever for such actions.</p></div>
            <div style="display: flex;"><p>5.4</p><p style="margin-left: 10px;">The Company shall have the right to scan, filter, block and verify the Third Party Content and User Content for virus, bugs and other such software malware and retains the right to remove any Third Party Content and/or User Content at its sole discretion. Further, the Company shall make best efforts to filter the data, information and images in accordance with applicable laws.</p></div>
            <div style="display: flex;"><p>5.5</p><p style="margin-left: 10px;">Certain elements of the Platform may contain material submitted by other Users and/or third parties. The Company disclaims its responsibility for the content, accuracy, conformity to applicable laws of such material including advertising material forming part of the Platform. Responsibility for ensuring that material submitted for inclusion on the Platform complies with applicable laws is exclusively on the advertisers and third parties and the Company will not be responsible for any claim, error, omission and/or inaccuracy arising out of or in relation to such material. The Company reserves the right to omit, suspend and/or change the position of any material submitted for insertion on the Platform. Acceptance of advertisements on the Platform shall be subject to the Company’s terms and conditions applicable to advertisements.</p></div>
            <div style="display: flex;"><p>5.6</p><p style="margin-left: 10px;">The User shall abide by all copyright notices, intellectual property rights, information and restrictions contained in any content accessed through the Services.</p></div>
            
            <b><li>Registration Data</li></b>
            <div style="display: flex;"><p>6.1</p><p style="margin-left: 10px;">The User shall be entitled to use the Platform for any purpose set out therein after getting registered on the Platform and after furnishing all relevant information relating to the User as sought on the Platform. The User shall be solely responsible for maintaining and preserving the confidentiality of its User Id and Password.</p></div>
            <div style="display: flex;"><p>6.2</p><p style="margin-left: 10px;">The User shall be solely responsible for the accuracy and correctness of all such detail/information given by the User during registration. The Company may at its sole discretion verify those details, if it deems fit. If the Company has reason to doubt the correctness and/or authenticity of any detail/information furnished by the User or in case any information furnished by the User is found incorrect, false or misleading then the Company shall be entitled to cancel or suspend the registration of the User permanently or for such period as the Company deems fit.</p></div>
            <div style="display: flex;"><p>6.3</p><p style="margin-left: 10px;">The User hereby grants the Company the right to use the information the User supplies to the Company for the purpose of the Services and conducting background and credit check wherever deemed necessary. The User hereby agrees to grant the Company a non-exclusive, fully paid and royalty-free, transferable, sub-licensable, worldwide license and  right to exercise the copyright, publicity, and database rights the User has in his/her information, in any media now or in future known, with respect to the User’s information.</p></div>
            <div style="display: flex;"><p>6.4</p><p style="margin-left: 10px;">The User confirms that the Company shall not be responsible for:</p></div>
            <div style="display: flex; margin-left: 30px;"><p>(a)</p><p style="margin-left: 10px;">Any deficiency in Service for reasons which are attributable to the User.</p></div>
            <div style="display: flex; margin-left: 30px;"><p>(b)</p><p style="margin-left: 10px;">Any loss and/or damage to the User due to incorrect, incomplete and/or false information furnished by the User.</p></div>
            <div style="display: flex; margin-left: 30px;"><p>(c)</p><p style="margin-left: 10px;">Any technical or non-technical payment related snags which the User may face through the use of the third-party payment gateways as provided by the Company on the Platform. Any deficiency in payment of consideration payable by the Users connected through the Platform, shall not be the responsibility of the Company and the Company shall not be directly or indirectly be held liable in respect to the same.</p></div>
            <div style="display: flex;"><p>6.5</p><p style="margin-left: 10px;">The User shall be liable to be prosecuted and/or punished under applicable laws for furnishing false, incorrect, incomplete and/or misleading information to the Company.</p></div>
            
            <b><li>Representation, Warranties, Acknowledgement, Covenants,  Undertakings And Obligations</li></b>
            <div style="display: flex;"><p>7.1</p><p style="margin-left: 10px;">The User represents and warrants that it has the legal capacity and authority to enter into this Agreement, register on the Platform and that the User is the owner and/or authorized to share the information the User gives on the Platform. The User confirms that the information is correct, complete, accurate, not misleading, does not violate any statutory enactment, notification, order, circular, policy, rules and regulations, is not injurious to any person and/or property.</p></div>
            <div style="display: flex;"><p>7.2</p><p style="margin-left: 10px;">The User acknowledges and understands that the Company does not have any control on accuracy of information/detail submitted by anybody on the Platform and therefore agrees that the Company shall not be responsible for any loss, damage, cost, expenses, etc. due to inaccuracy of any detail/information submitted by the User or anybody else on the Platform.</p></div>
            <div style="display: flex;"><p>7.3</p><p style="margin-left: 10px;">The User represents, warrants and covenants that information submitted by the User:</p></div>
            <div style="display: flex; margin-left: 30px;"><p>(a)</p><p style="margin-left: 10px;">is correct, complete, relevant and accurate;</p></div>    
            <div style="display: flex; margin-left: 30px;"><p>(b)</p><p style="margin-left: 10px;">is not fraudulent;</p></div>
            <div style="display: flex; margin-left: 30px;"><p>(c)</p><p style="margin-left: 10px;">does not and shall not infringe any third-party’s intellectual property, trade secret and/or other proprietary rights and/or rights of publicity and/or privacy;</p></div>
            <div style="display: flex; margin-left: 30px;"><p>(d)</p><p style="margin-left: 10px;">shall not be defamatory, libelous, unlawfully threatening and/or unlawfully harassing;</p></div>
            <div style="display: flex; margin-left: 30px;"><p>(e)</p><p style="margin-left: 10px;">shall not be indecent, obscene and/or contain pornography and/or contain any thing which is prohibited under any prevailing laws, rules & regulations, order of any court, forum, statutory authority;</p></div>
            <div style="display: flex; margin-left: 30px;"><p>(f)</p><p style="margin-left: 10px;">shall not be seditious, offensive, abusive, liable to incite racial and/or religious hatred, discriminatory, menacing, scandalous, inflammatory, blasphemous, in breach of confidence, in breach of privacy and/or which may cause annoyance and/or inconvenience;</p></div>
            <div style="display: flex; margin-left: 30px;"><p>(g)</p><p style="margin-left: 10px;">does not constitute and/or encourage conduct that would be considered a criminal offence, give rise to civil liability, and/or otherwise be contrary to the law;</p></div>
            <div style="display: flex; margin-left: 30px;"><p>(h)</p><p style="margin-left: 10px;">shall not be or include material that is technically harmful (including, without limitation, computer viruses, logic bombs, Trojan horses, worms, harmful components, corrupted data or other malicious software or harmful data) or other computer programming routines that may damage, detrimentally  interfere with, diminish value of, surreptitiously intercept or expropriate any system, data or personal information;</p></div>
            <div style="display: flex; margin-left: 30px;"><p>(i)</p><p style="margin-left: 10px;">shall not create liability and/or claims for the Company;</p></div>
            <div style="display: flex; margin-left: 30px;"><p>(j)</p><p style="margin-left: 10px;">shall not be in the nature of political campaigning, commercial solicitation, chain letters, mass mailings and/or any form of ‘spam’;</p></div>
            <div style="display: flex; margin-left: 30px;"><p>(k)</p><p style="margin-left: 10px;">shall not be illegal in any other way.</p></div>
            <div style="display: flex;"><p>7.4</p><p style="margin-left: 10px;">The Company reserves the right to remove and/or edit any detail/information that the Company in its sole discretion deems appropriate. The User confirms that he/she/it shall abide by all notices and all the terms and conditions (updated from time to time) contained and mentioned herein.</p></div>
            <div style="display: flex;"><p>7.5</p><p style="margin-left: 10px;">The User undertakes and confirms that the User shall not use the Platform, Services and/or materials therein for any purpose that is unlawful and/or prohibited by the terms of this Agreement and/or under any applicable laws, rulings, notifications, orders of any court or statutory authority</p></div>
            <div style="display: flex;"><p>7.6</p><p style="margin-left: 10px;">The User shall not use the Platform, Services and/or materials therein in any manner which could damage, disable, overburden and/or impair the Platform and/or any Services therein and/or the network(s) connected to the Platform and interfere with another User’s use and enjoyment of the Platform and/or Services therein.</p></div>
            <div style="display: flex;"><p>7.7</p><p style="margin-left: 10px;">The User shall not attempt to gain unauthorized access to any Service on the Platform, other users’ account(s), computer systems and/or networks connected to the Platform through hacking, password mining and/or any other means. The User shall not attempt to obtain any materials or information through any means not intentionally made available to the User through the Platform.</p></div>
            <div style="display: flex;"><p>7.8</p><p style="margin-left: 10px;">The Company shall not be responsible and/or liable for any claims, losses and/or damages accruing to or affecting the User, as a result of: (i) the User’s access or reliance on any Work Assignments appearing on the Platform which are posted by Requirement Listing Companies; and/or (ii) the User i.e. IC and/or Third Party Service Provider engaging with the Requirement Listing Companies in regard to Work Assignments on agreed terms; and/or (iii) the User i.e. IC and/or Requirement Listing Companies engaging with any Third Party Service Provider; and/or (iv) any defects/deficiencies in the services provided by any Third Party Service Provider; and/or (v) any claims in relation to the payment of any agreed terms between the User engaging with each other through the Platform; and/or (vi) any claims/actions (including consumer claims) arising thereto; and/or (vii) any violation by the Users of the rights of third parties, including without limitation, infringement of copyright, trademark, service mark, patent, trade secret, privacy or other intellectual property or proprietary right or misappropriation of any property. The advertisement/advice (if any) received via the Platform should not be relied upon for personal, legal and/or financial decisions and the User should consult an appropriate professional for specific advice tailored to your situation.</p></div>
            <div style="display: flex;"><p>7.9</p><p style="margin-left: 10px;">The User shall be solely responsible for obtaining and maintaining any equipment or ancillary services needed to connect to, access the Platform or otherwise use the Services, including, without limitation, modems, hardware, software, and long distance or local telephone service. The User shall be responsible for ensuring that such equipment or ancillary services are compatible with the Services.</p></div>
            
            <b><li>Payment Terms</li></b>
            <div style="display: flex;"><p>8.1</p><p style="margin-left: 10px;">The User shall be entitled to use his/her/its valid credit/debit cards, online/net banking facility, tele banking facility to make payment for the Subscription Fee for the Platform. The User shall be bound to use his/her/its own credit/debit cards, online banking accounts to make any payment. The User undertakes and confirms that he/she/it would furnish correct, complete and accurate detail of credit/debit cards, online banking accounts and shall be solely responsible for any cost, expense, loss and/or damage which the User may suffer due to furnishing of wrong detail/information relating to his/her credit/debit cards or online banking accounts.</p></div>
            <div style="display: flex;"><p>8.2</p><p style="margin-left: 10px;">The Company confirms that the details provided by the User in this regard shall be kept confidential and shall not be disclosed to any third-party except to the extent required under the rules of any stock exchange and/or by applicable laws, regulations and/or processes of any government authority and/or in connection with any judicial process regarding any legal action, suit and/or proceeding arising out of and/or relating to this Agreement and/or relating to provision of Services by the Company.</p></div>
            <div style="display: flex;"><p>8.3</p><p style="margin-left: 10px;">The User here represents and acknowledges that all payments made by the User to the Company shall be strictly on a non-refundable basis and the Subscription Fees paid by a User towards the Services are non – refundable and non-transferable.</p></div>
            <div style="display: flex;"><p>8.4</p><p style="margin-left: 10px;">The Company shall take all reasonable precautions to protect the information provided by the User. However, it is expressly provided and accepted by the User that the Company shall not be responsible in any manner whatsoever for any third-party action which results in the information provided by the User being exposed, misused and/or fraudulently misused in any manner whatsoever by such third- party.</p></div>

            <b><li>Force Majeure</li></b>
            <p>The Company shall not be liable for any failure and/or delay on its part in performing any of its obligation under this Agreement and/or for any loss, damage, costs, charges and expenses incurred and/or suffered by the User by reason thereof if such failure and/or delay shall be result of or arising out of Force Majeure Event set out herein. During the continuation of Force Majeure Event, the obligations of the Company under the Agreement shall stand suspended till ceasing of the Force Majeure Event. The Company shall make its best endeavours to resolve any issues in regard to Force Majeure Event at the earliest and keep the Users updated on the same through notification on the Platform.</p>
            <p>A “<b>Force Majeure Event</b>” means any event due to any cause beyond the reasonable control of the Company, including, without limitation, unavailability of any communication system, sabotage, fire, flood, earthquake, explosion, acts of God, civil commotion, strikes, lockout, and/or industrial action of any kind, breakdown of its facilities, riots, insurrection, terrorist attack, hostilities whether war be declared or not, acts of government, governmental orders or restrictions, pandemics, epidemics, breakdown and/or irregularities in networks, bugs, viruses, breakdown of software, hacking of the Platform and/or contents provided for availing the Services under the Platform.</p>
            
            <b><li>Warranty Disclaimer</li></b>
            <div style="display: flex;"><p>10.1</p><p style="margin-left: 10px;">The User acknowledges that the Company has no control over and no duty to take any action regarding (i) the User gaining access to the Platform and/or its content and/or Services; (ii) what effect the Platform and/or its content and/or Services may have on the User and/or any third-party; (iii) how the User may interpret or use the Platform and/or its content and/or Services; (iv) what actions the User may take as a result of having been exposed to the Platform and/or its content and/or Services. The User releases the Company from all liability whether financial or otherwise for the User having accessed the Platform and/or its content and/or Services.</p></div>
            <div style="display: flex;"><p>10.2</p><p style="margin-left: 10px;">The Company provides access to the Platform on an ‘as is’ and ‘as available’ basis. Neither does the Company nor do any third parties including Third Party Service Providers (except as expressly stated by such Third Party Service Provider), provide any warranty or guarantee, express or implied, as to the accuracy, fitness, merchantability, availability, quality, performance, completeness or suitability of the Platform and/or its content and/or Services, for any specific purpose. The User acknowledges that the Platform and/or its content and/or Services may contain inaccuracies or errors and the Company expressly excludes liability whether financial or otherwise for any such inaccuracies or errors to the fullest extent permitted by law.</p></div>
            <div style="display: flex;"><p>10.3</p><p style="margin-left: 10px;">Although the Company has taken adequate safeguards, the Company makes no guarantee of confidentiality and/or privacy of any User communication or information transmitted on the Platform and/or for the Services and/or on any web-site linked to the Platform. The User uses this Platform and/or Services and/or on any web-site linked to the Platform at his/her/its own risk.</p></div>
            <div style="display: flex;"><p>10.4</p><p style="margin-left: 10px;">The Platform and/or its content and/or Services may contain views, opinions and recommendations of Users of the Platform and/or Services, Third Party Content, User Content, advertisers, third-party information providers and organizations. The Platform does not warrant, represent and/or endorse the correctness, authenticity, accuracy, completeness, reliability or validity of any such information displayed, uploaded, distributed, posted, emailed, transmitted or otherwise contributed through the Platform by anybody other than the Company. The Company assumes no responsibility to keep the content current, complete, up-to-date, valid,  or accurate. The Company shall not be responsible in any manner whatsoever whether financially or otherwise for any errors and omissions in the materials and/or content even if such errors or omissions are due to negligence. The User acknowledges that any reliance upon any such view, opinion, advice, recommendation or information, User Content, Third Party Content shall be at the User’s sole risk.</p></div>
            <div style="display: flex;"><p>10.5</p><p style="margin-left: 10px;">The Company does not warrant the accuracy, reliability, credibility or completeness of the Platform and/or its content and/or its Services. The Company makes no warranty that its Platform and/or its content are appropriate or available for use in other locations. Those who access the Platform and/or its content from territories other than India shall do so at their own risk and hereby unconditionally represent and undertake that they are responsible for full compliance with their local laws and the applicable laws of the Republic of India.</p></div>
            <div style="display: flex;"><p>10.6</p><p style="margin-left: 10px;">The User shall be solely responsible to maintain the confidentiality and security of his/her/its account, User Id, Password, login credentials and the Company shall not be responsible for any losses arising out of the unauthorized access and/or use of the User’s account as a result of the User not following the terms and conditions stated herein.</p></div>
            <div style="display: flex;"><p>10.7</p><p style="margin-left: 10px;">The Company shall be under no liability whether financial or otherwise of any nature whatsoever in respect of any loss, damage, claim or fraud suffered directly or indirectly by the User as a result of the User viewing, accessing, visiting and/or otherwise using the Platform.</p></div>
            <div style="display: flex;"><p>10.8</p><p style="margin-left: 10px;">The User expressly understands and agrees that the Company, its shareholders, associate companies, directors, employees, representatives, agents and partners, shall not be liable to the User for any direct, indirect, incidental, special, consequential, punitive or exemplary damages, including, but not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses resulting from:</p></div>
            <div style="display: flex; margin-left: 30px;"><p>(a)</p><p style="margin-left: 10px;">the User’s use or inability to use the Platform;</p></div>
            <div style="display: flex; margin-left: 30px;"><p>(b)</p><p style="margin-left: 10px;">any changes made to the Platform;</p></div>
            <div style="display: flex; margin-left: 30px;"><p>(c)</p><p style="margin-left: 10px;">temporary or permanent cessation of the Platform, the Services, the Company’s business or any part thereof;</p></div>
            <div style="display: flex; margin-left: 30px;"><p>(d)</p><p style="margin-left: 10px;">any unauthorized access to or alteration of the User transmissions or data, if any;</p></div>
            <div style="display: flex; margin-left: 30px;"><p>(e)</p><p style="margin-left: 10px;">statements or conduct of any third-party on the Platform; and</p></div>
            <div style="display: flex; margin-left: 30px;"><p>(f)</p><p style="margin-left: 10px;">any fraud, security breach, misconduct or negligence by any third parties and/or advertisers and/or service providers.</p></div>
            <div style="display: flex;"><p>10.9</p><p style="margin-left: 10px;">The Company does not warrant that access and use of its Platform and/or its content and/or Service will be uninterrupted, timely, secure and error-free since the same could be affected by overload/breakdown of receiving network, servers or applications; system failures; and frauds out of the Company’s control. The Company  shall not be liable for any loss or damage whether financial or otherwise caused by a distributed denial-of-service attack, viruses or other technologically harmful material that may infect the User’s device, programs, data or other proprietary material due to use of the Platform.</p></div>
            <div style="display: flex;"><p>10.10</p><p style="margin-left: 10px;">The Company does not endorse in anyway any contents of the work profiles of ICs enrolled on its Platform and/or Work Assignments listed by the Requirement Listing Companies and/or service profiles listed by Third Party Service Providers. The User i.e. Requirement Listing Companies shall verify the veracity of all information on his/her own before undertaking reliance and acting thereupon. The User expressly agrees and understands that the Services, materials, consultations, content and information offered on the Platform is not a substitute for professional advice and the Company disclaims all liability whether financial or otherwise for any claims arising thereto.</p></div>
            <div style="display: flex;"><p>10.11</p><p style="margin-left: 10px;">The Company is not responsible for the availability or content of any third-party material you access through the Platform. The Company does not endorse and is not responsible or liable for any content, advertising, products, services or information of or relating to a third-party (including payment for and delivery of such products or services). The Company shall not be responsible for any damage, loss or offence caused by or, in connection with, any content, material, advertising, products, services or information of any third-party that may be available on the Platform. Any terms, conditions, warranties or representations associated with such dealings, are solely between the User and the relevant provider of the service and the User agrees to indemnify the Company for any claims arising thereto.</p></div>
            <div style="display: flex;"><p>10.12</p><p style="margin-left: 10px;">The Company shall not be responsible and/or liable whether financially or otherwise in any manner whatsoever for any third-party action which results in the information provided by the User being exposed, misused and/or by such third-party who accessed such information without any authorization from the Company.</p></div>
            <div style="display: flex;"><p>10.13</p><p style="margin-left: 10px;">The Company may use a third-party as a payment gateway. By subscribing to the Company’s Services, the User expressly authorizes a third-party payment gateway to charge the User’s credit card/debit card. The Company disclaims any and all liability accruing to the User as a result of any fraud, misuse and/or duplicate transactions arising thereto.</p></div>
            <div style="display: flex;"><p>10.14</p><p style="margin-left: 10px;">The Company neither warrants nor represents that use of the Services by the User will not infringe the rights of third parties not owned by or affiliated with the Company.</p></div>
            <div style="display: flex;"><p>10.15</p><p style="margin-left: 10px;">There may be delay in provision of Services due to factors beyond the reasonable control of the Company and therefore the Company shall not be held responsible and liable for any delay and/or failure in performance of Services by the Company due to such factors. The Platform may be subject to changes and/or improvements periodically. During the process of up-gradation, repair, maintenance and/or improvements, contents/systems/programs/software may be suspended for any use. The Company shall not be liable for any inconvenience, loss, damage, cost, expenses which may be incurred by the User due to unavailability of the Platform for any use. The Company does not warrant that the software will work on all networks and/or will be available in all geographical areas.</p></div>

            <br/><b><li>Personal, Non-Commercial and Non-Assignable Use</li></b>
            <p>By registering on the Platform the User confirms that use of the Platform shall be for his/her personal and/or commercial purpose (as may be applicable). The User confirms that the User’s right to access, use, deal with or transact at the Platform cannot be assigned to any other person and custody of the User Id and Password to access, deal with or transact at the Platform shall be the sole responsibility of the User and the User shall be responsible and liable for any misuse of the User Id and Password assigned to the User by the Company.</p>
            
            <b><li>Privacy Policy</li></b>
            <p>Privacy Policy of the company can be accessed here (Link to Privacy Policy to be inserted)</p>
            
            <b><li>Links to other Platforms</li></b>
            <p>The Platform may contain hyperlinks to Platforms operated by persons other than the Company (hereinafter referred to as the “<b>Web Links</b>”). Such Web Links are provided for the User’s reference and convenience only and they do not constitute as any recommendation and/or advice and should not be relied upon in making and/or refraining from making any decision. The Company reserves its right to remove any such Web Links anytime without any prior notice. The Company is not under obligation to review the contents of Web Links and therefore does not control the contents of those Web Links. The User leaves the Platform by clicking on Web Links and goes to respective internet Platforms and use of those Web Links shall be regulated by terms provided on respective Platforms. The Company is neither acting as publisher of contents of those Web Links nor endorser of any representation published on those Web Links. The User confirms that any access of Web Links by the User shall be entirely at the User’s own risk.</p>
            
            <b><li>Description and Fees</li></b>
            <p>The Company shall endeavor to the best extent possible to be as accurate while providing Service descriptions and fees. However, the Company does not warrant that descriptions and/or other content and/or fees related to the Services and/or other information and content contained in this Platform is accurate, complete, reliable, current and error-free.</p>
            
            <b><li>Promotional Material</li></b>
            <p>The Company shall be entitled to send and the User gives his/her consent to receive, information/ offers/ promotional mails and messages pertaining to the Services offered by the Company/the associate companies from time to time.</p>
            <p>In case the User wishes to de-activate any promotional material being provided to the User, the User shall notify the Company at [●]. <b><i>[SAM & Co Note: Please add the email address</i></b></p>
            
            <b><li>Indemnity and Limitation of Liability</li></b>
            <p>Without prejudice to the rights of the Company under law, in equity and/or under this Agreement, the User agrees to protect, hold harmless and indemnify the Company, its shareholders, associate companies, directors, employees, representatives, agents and partners, to the fullest extent, from and against any and all claims, demands, damages, obligations, losses, liabilities, judgments, settlements, costs and expenses (including reasonable attorneys’ fees and costs) arising from or relating to: (i) the User’s access, use or misuse of the Platform; and/or (ii) the User’s breach or alleged breach of this Agreement and/or breach of the User’s representations, warranties, guarantees, covenants, obligations and undertakings; and/or (iii) any violation by the User of any applicable laws; and/or (iv) any violation by the User of the Company’s rights and/or the rights of third parties, including without limitation, infringement of copyright, trademark, service mark, patent, trade secret, privacy or other intellectual property or proprietary right or misappropriation of any property.</p>
            <p>Notwithstanding anything contained in the Agreement, the maximum liability of the Company for rendering the Services, whether financial or otherwise for any reasons whatsoever under the Agreement shall not exceed the Subscription Fees received by the Company from any User under the Agreement.</p>
            
            <b><li>Termination</li></b>
            <div style="display: flex;"><p>17.1</p><p style="margin-left: 10px;">The Company may, at any time, terminate or suspend any and all Services and/or access to the Platform immediately, without prior notice and/or liability if:</p></div>
            <div style="display: flex; margin-left: 30px;"><p>(a)</p><p style="margin-left: 10px;">The User breaches any of the terms or conditions of this Agreement and/or other incorporated agreements and/or guidelines.</p></div>
            <div style="display: flex; margin-left: 30px;"><p>(b)</p><p style="margin-left: 10px;">Requests by law enforcement and/or other government agencies.</p></div>
            <div style="display: flex; margin-left: 30px;"><p>(c)</p><p style="margin-left: 10px;">Discontinuance and/or material modification to the Platform and/or Service (or any part thereof).</p></div>
            <div style="display: flex; margin-left: 30px;"><p>(d)</p><p style="margin-left: 10px;">Unexpected technical and/or security issues and/or problems.</p></div>
            <div style="display: flex; margin-left: 30px;"><p>(e)</p><p style="margin-left: 10px;">Engagement by the User in fraudulent and/or illegal activities and/or suspected impersonation and/or due to result of any background search and/or credit check conducted on the User and/or any breach or threatened breach/ infringement of Intellectual Property Rights of the Company.</p></div>
            <div style="display: flex; margin-left: 30px;"><p>(f)</p><p style="margin-left: 10px;">Non - payment of Subscription Fees owed by the User in connection with the use of Platform and/or Services.</p></div>
            <div style="display: flex;"><p>17.2</p><p style="margin-left: 10px;">Termination of the User account includes:</p></div>
            <div style="display: flex; margin-left: 30px;"><p>(a)</p><p style="margin-left: 10px;">Removal of access to all offerings within the Service and the Platform.</p></div>
            <div style="display: flex; margin-left: 30px;"><p>(b)</p><p style="margin-left: 10px;">Deletion of the User Id and Password and all related information, files and content associated with or inside the User account (or any part thereof).</p></div>
            <div style="display: flex; margin-left: 30px;"><p>(c)</p><p style="margin-left: 10px;">Barring of further use of the Platform and/or Service.</p></div>
            <div style="display: flex;"><p>17.3</p><p style="margin-left: 10px;">The User hereby agrees that all terminations for cause shall be made in Company’s sole discretion and that Company shall not be liable to the User or any third-party for any termination of the User account, any associated email address, or access to the Services. Any Subscription Fees paid hereunder are non-refundable. All provisions of this Agreement which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions and warranty disclaimers.</p></div>

            <b><li>Governing Law</li></b>
            <p>This Agreement shall be governed by, construed interpreted, and enforced in accordance with the laws of India. Except for injunctive reliefs, specific performance and damages any other disputes arising in respect hereto shall be subject to exclusive jurisdiction of the Courts of Gurgaon, Haryana.<b><i>[SAM & Co Note: To Be Discussed]</i></b></p>
            
            <b><li>General Provisions</li></b>
            <div style="display: flex;"><p>19.1</p><p style="margin-left: 10px;"><b>No Partnership</b></p></div>
            <div style="display: flex; margin-left: 30px;"><p>None of the provisions of this Agreement shall be deemed to constitute a partnership or agency between the User and the Company and the User shall have no authority to bind the Company in any manner whatsoever.</p></div>
            <div style="display: flex;"><p>19.2</p><p style="margin-left: 10px;"><b>Electronic communication</b></p></div>
            <div style="display: flex; margin-left: 30px;"><p>The User consents and gives his/her consent to receive communications from the Company electronically/through electronic records. The Company will communicate with the User by e-mail and/or by posting notices on this Platform. The User agrees that all agreements, notices, disclosures and other communications that the Company provides to the User electronically satisfy all legal requirement of adequate service of notice/electronic record. This document is an electronic record in terms of Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical and/or digital signatures.</p></div>
            <div style="display: flex;"><p>19.3</p><p style="margin-left: 10px;"><b>Assignment</b></p></div>
            <div style="display: flex; margin-left: 30px;"><p>The Company can assign its rights and/or obligations provided herein or arising out of this Agreement to any person. The User cannot assign its rights and remedies and/or transfer its obligations under this Agreement without prior written consent of the Company.</p></div>
            <div style="display: flex;"><p>19.4</p><p style="margin-left: 10px;"><b>Severability</b></p></div>
            <div style="display: flex; margin-left: 30px;"><p>Should any provision of this Agreement be determined to be unenforceable and/or invalid, or any transaction contemplated hereby determined to be unlawful by any court of law, or competent government body for any reason, all other provisions shall continue (except if this Agreement stands terminated) in full force and effect.</p></div>
            <div style="display: flex;"><p>19.5</p><p style="margin-left: 10px;"><b>Grievances</b></p></div>
            <div style="display: flex; margin-left: 30px;"><p>In case of any queries and/or grievances and/or assistance required, if any, the User can reach out to Mr. [●], our Grievance Redressal Office and email us all queries and/or complaints on [●] or contact us on [●]. <b>[SAM & Co. Note: Please provide relevant details.]</b></p></div>
        </ol>
    </ol>
      </div>
    </div>
    <!-- <my-footer></my-footer> -->
  </div>
</template>
<script>
// import header from "../components/Header";
// import footer from "../components/Footer";

export default {
  components: {
    // myFooter: footer,
    // myHeader: header,
  },
  data: () => ({
    html: ``,
    mainList: ["Recitals"],
    details: {
      recitals: [
        "This document is an electronic record in terms of Information Technology Act, 2000 and rules there under as applicable and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology (Amendment) Act, 2008. This electronic record is generated by a computer system and does not require any physical or digital signatures. This electronic record is generated by a computer system and does not require any physical or digital signatures.",
        "This document is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries Guidelines) Rules, 2011 that require publishing the rules and regulations, privacy policy and user agreement for access or usage of www.gig4ce.com. [SAM & Co. Notes: Please confirm the domain name]",
        "www.gig4ce.com (“Website”) and our application gig4ce (jointly referred to as “Platform”) is owned and operated by Gigforce Private Limited, a company incorporated under the Companies Act, 2013 vide CIN - U74999HR2019PTC082217, having its corporate office at House no 1872 Sector-2 BALLABHGARH Faridabad HR 121004 IN [SAM& Co. Note: Please confirm the same] hereinafter referred to as the “Company” (which expression shall, unless it be repugnant to the context or meaning thereof, include its successors and assigns).",
        `Subject to the terms and conditions of this Agreement (as defined hereinafter), the Company shall make available through the Platform being an online aggregator platform and connecting medium for (i) persons to provide their work profiles, availability status and interest areas for rendering services in specific areas in time bound manner (“Independent Consultants” or “IC”); (ii) for corporate entities to provide their requirements of availing services of IC’s on specific assignments (“Work Assignments”) in regard to their business establishment (“Requirement Listing Companies”); (iii) third party service provider entities who are interested in rendering services including skill improvement seminars, e-workshops for enhancing professional skills, preparation of IC’s for interview and finalising of their work profile, insurances and social security, payroll services, pay roll compliance services, staffing service providers, legal and account related services, credit score checks and background checks  etc. (“Third Party Service Providers”) making available the information being uploaded by IC, Requirement Listing Companies and Third Party Service Providers and enabling parties to have better access to services and/or service providers in consideration of the subscription fee which may be fixed by the Company from time to time and reflected on the Platform while availing the services and as may be charged by the Company at its sole discretion from time to time (“Subscription Fees”) on the terms and conditions set out herein (“Services”). `,
        `It is expressly clarified that the Company may render certain specific Services without any charges for a specific time period as may be deemed fit by the Company and which shall be notified on the Platform from time to time. It is expressly stated that the User shall be solely responsible and liable for the information, images and data uploaded by the User.. The Company has the absolute right, at any time, to render the Services being offered through the Platform through any party whatsoever.`,
        `Use of this Platform is regulated by terms and conditions provided herein. By clicking on the “I ACCEPT” button, you are consenting to be bound by these Agreement and please ensure that you read and understand all these user terms before you use the Platform. If you do not accept any of the user terms, then please do not use the site or avail any of the services being provided therein. Your agreement to these user terms shall operate as a binding agreement between you and the Company in respect of the use and services of the Platform. Your access, visit, dealing, transacting and/or otherwise using this Platform shall be treated as your unconditional acceptance of these terms in entirety and the same shall be deemed that you have accepted these terms fully and you have gone through and understood these terms completely and these terms shall be treated as a legally binding and enforceable Agreement between the Company and you (“Acceptance”). If you do not agree with any of these terms or all of these terms, then you should stop dealing with and/or not initiate or undertake any transaction on this Platform.`,
        `The information and Services offered on this Platform or through other mediums should not be considered as a substitute for professional advice. The User's use of the Platform and/or Services is subject to the additional disclaimers and caveats that may appear throughout the Platform. The Company, its associate companies, subsidiaries assume no responsibility for any consequence relating directly and/or indirectly to any action and/or inaction that the User takes based on the information, Services and other materials on this Platform. While the Company strives to keep the information relating to the various Services offered on this Platform accurate, complete and up-to-date, the Company, its subsidiaries, affiliates, associate companies, consultants, partners, agents and suppliers cannot guarantee, and will not be responsible for any damage and/or loss related to, the accuracy, completeness or timelines of the information.</br></br> It is expressly provided that the data pertaining to the Services offered through the Platform is provided by third parties and the same is not owned by the Company.`,
        `The Company may change, suspend and/or discontinue the Services at any time, including the availability of any feature, database and content on the Platform. The Company may also impose limits on certain features and Services and/or restrict the User's access to parts and/or all of the Services without notice and/or liability to the Users.`,
        `All kind of correspondence should be addressed to Company’s office address as given above.`,
      ],
    },
  }),
  methods: {
    makeHtml() {
      this.html = `<ol type="I">`;
      this.mainList.forEach((item) => {
        this.html += `<li> <span class="is-size-4">${item}</span>`;
        this.html += `<ol>`
        this.details[item.toLowerCase()].forEach(text => {
             this.html += `<li class="is-size-6">${text}</li>`   
        })
        this.html += `</ol></li>`
      });
      this.html += `</ol>`
    },
  },
  mounted(){
      this.makeHtml()
  }
};
</script>
<style>
.top-margin {
  margin-top: 7rem;
}
@media only screen and (max-width: 768px) {
    .container {
      margin: 8%;
    }
}
</style>