<template>
    <div class="caseStudies-container">
        <myMkHeader></myMkHeader>
        <h1 class="display--1 mar--t--60 has-text-weight-semibold">Case Studies</h1>
        <div class="caseStudies-row">
            <div class="caseStudies-image-column">
                <div class="caseStudies-image-container">
                    <img
                        class="media-image"
                        alt=""
                        src="../assets/Soma-Streatfood-logo.jpg"
                    />
                </div>
                 <h1 class="caseStudies-card-heading display--2 has-text-left has-text-weight-bold">
                     Driveline met a 7X increase in its temporary workforce and reduced costs by 37% during an unanticipated peak
                </h1>
            </div>
            <div class="caseStudies-image-column">
                <div class="caseStudies-image-container">
                    <img
                        class="media-image"
                        alt=""
                        src="../assets/Techweek-logo.jpg"
                    />
                </div>
                 <h1 class="caseStudies-card-heading display--2 has-text-left has-text-weight-bold">
                     Techweek looked to Wonolo to cut staffing costs without sacrificing quality
                </h1>
            </div>
            <div class="caseStudies-image-column">
                <div class="caseStudies-image-container">
                    <img
                        class="media-image"
                        alt=""
                        src="../assets/Soma-Streatfood-logo.jpg"
                    />
                </div>
                 <h1 class="caseStudies-card-heading display--2 has-text-left has-text-weight-bold">
                     Driveline met a 7X increase in its temporary workforce and reduced costs by 37% during an unanticipated peak
                </h1>
            </div>
        </div>
        <div class="caseStudies-row">
            <div class="caseStudies-image-column">
                <div class="caseStudies-image-container">
                    <img
                        class="media-image"
                        alt=""
                        src="../assets/Soma-Streatfood-logo.jpg"
                    />
                </div>
                 <h1 class="caseStudies-card-heading display--2 has-text-left has-text-weight-bold">
                     Driveline met a 7X increase in its temporary workforce and reduced costs by 37% during an unanticipated peak
                </h1>
            </div>
            <div class="caseStudies-image-column">
                <div class="caseStudies-image-container">
                    <img
                        class="media-image"
                        alt=""
                        src="../assets/Techweek-logo.jpg"
                    />
                </div>
                 <h1 class="caseStudies-card-heading display--2 has-text-left has-text-weight-bold">
                     Techweek looked to Wonolo to cut staffing costs without sacrificing quality
                </h1>
            </div>
            <div class="caseStudies-image-column">
                <div class="caseStudies-image-container">
                    <img
                        class="media-image"
                        alt=""
                        src="../assets/Soma-Streatfood-logo.jpg"
                    />
                </div>
                 <h1 class="caseStudies-card-heading display--2 has-text-left has-text-weight-bold">
                     Driveline met a 7X increase in its temporary workforce and reduced costs by 37% during an unanticipated peak
                </h1>
            </div>
        </div>
        <myFooter></myFooter>
    </div>
</template>
<script>
import footer from "../components/Footer";
import Mkheader from "../components/MarketingHeader";

export default {
    components: {
    myFooter: footer,
    myMkHeader: Mkheader,
  },
  data: () => ({
    
  }),
  methods: {
    
  },
}
</script>
<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

.caseStudies-container{
    max-width: 1400px;
    min-width: 280px;
    margin: 0 auto;
}
.caseStudies-row{
    width: 90%;
    // margin: 80px auto;
    // margin: 30px auto;
    display: flex;
    max-width: 1400px;
    min-width: 280px;
    margin: 40px auto;
}
.caseStudies-image-column{
    width: 33%;
    padding: 24px;
    border-radius: 18px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    cursor: pointer;
}
.caseStudies-image-column:hover{
    box-shadow: 12px 12px 40px 0 rgba(55,84,170,.1),12px 12px 20px 0 rgba(0,0,0,.1),-12px -12px 80px 0 white,inset 4px 4px 20px 0 white;
    transition: box-shadow 310ms ease-in-out,background-image 310ms ease-in-out,transform .35s;
    transform: scale(1.05);
    background-image: linear-gradient(139deg,#ffffff00 -7%,#f6f6f600 96%);
}
.caseStudies-image-container{
    width: unset;
    height: 225px;
}
.caseStudies-image-container img{
    border-radius: 10px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
@media only screen and (max-width: 768px){
    .caseStudies-row{
        flex-direction: column;
    }
    .caseStudies-image-column{
        width: 100%;
    }
}

</style>